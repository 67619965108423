
    <!-- BEGIN: Page Banner Section -->
    <section class="pageBannerSection">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="pageBannerContent text-center">
                        <h2>Product Details</h2>
                        <div class="pageBannerPath">
                            <a routerLink="/">Home</a>&nbsp;&nbsp; / &nbsp;&nbsp;<span>Product Details</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- END: Page Banner Section -->


    <!-- END: Cart Page Section -->
         <!-- shop-details-area-start -->
         <section class="shopdetails-area grey-bg pb-50">
            <div class="container">
               <div class="row">
                  <div class="col-lg-12 col-md-12">
                    
                     <div class="tpdetails__area pb-30">
                        <div class="tpdetails__product mb-30">
                           <div class="tpdetails__title-box">
                              <h3 class="tpdetails__title">{{productDetails.name}}</h3>
                  
                           </div>
                           <div class="tpdetails__box productQuickView">
                              <div class="row">
                                 <div class="col-lg-6">
                                    <div class="productGalleryWrap">
                                        <!--<div class="productGalleryPopup">
                                            <div class="pgImage" *ngIf="productDetails.img != ''">
                                                <img src="{{productDetails.img}}" alt="Product Image"/>
                                            </div>
                                            <div class="pgImage" *ngIf="productDetails.img == ''">
                                             <img src="./assets/images/no_image.png" alt="Product Image"/>
                                            </div>
                                        </div>-->
                                        
                                        
                                          <div class="productGalleryPopup">
                                                <div class="pgImage" *ngIf="productDetails.img != ''">
                                                   <img src="{{selected_image}}" alt="Product Image"/>
                                                </div>

                                                <div class="pgImage" *ngIf="productDetails.img == ''">
                                                   <img src="./assets/images/no_image.png" alt="Product Image"/>
                                                </div>
                                                
                                          </div>
                                          <div class="productGalleryThumbWrap" *ngIf="productDetails.img != ''">

                                             <owl-carousel-o class="productGalleryThumbPopup" [options]="customOptions">
                                                   <ng-container *ngIf="productDetails.img != ''">
                                                      <ng-template  carouselSlide [id]="'1'" >

                                                      <div class="pgtImage" (click)="selectimage(productDetails.img);">
                                                         <img src="{{productDetails.img}}" alt="Product Image"/>
                                                      </div>

                                                      </ng-template>
                                                   </ng-container>

                                                   <ng-container *ngIf="productDetails.img1 != ''">
                                                      <ng-template  carouselSlide [id]="'2'" >
      
                                                         <div class="pgtImage" (click)="selectimage(productDetails.img1);">
                                                            <img src="{{productDetails.img1}}" alt="Product Image"/>
                                                         </div>
      
                                                         </ng-template>
                                                   </ng-container>

                                                   <ng-container *ngIf="productDetails.img2 != ''">
                                                      <ng-template  carouselSlide [id]="'3'" >
      
                                                         <div class="pgtImage" (click)="selectimage(productDetails.img2);">
                                                            <img src="{{productDetails.img2}}" alt="Product Image"/>
                                                         </div>
      
                                                      </ng-template>
                                                   </ng-container>

                                                </owl-carousel-o>   

                                          </div>


                                          <div class="productGalleryThumbWrap" *ngIf="productDetails.img == ''">

                                             <owl-carousel-o class="productGalleryThumbPopup" [options]="customOptions">
                                                   <ng-container >
                                                      <ng-template  carouselSlide [id]="'1'" >

                                                      <div class="pgtImage" >
                                                         <img src="./assets/images/no_image.png" alt="Product Image"/>
                                                      </div>

                                                      </ng-template>
                                                   </ng-container>

                                                  

                                                </owl-carousel-o>   

                                          </div>
                                        
                                        
                                    </div>
                                 </div>
                                 <div class="col-lg-6">
                                    <div class="product__details">
                                       <div class="product__details-price-box">
                                          <h5 class="product__details-price" style="color:black;"><span *ngIf="businessDetails != null">{{businessDetails.currency_symbol}}</span>{{productDetails.price}}</h5>
                                          <ul class="product__details-info-list" >
                                             
                                            
                                          </ul>

                                          <div *ngIf="productDetails.description != ''" [innerHTML] = "productDetails.description"></div>
                                       </div>
                                       <div class="product__details-cart">
                                          <div class="product__details-quantity d-flex align-items-center mb-15"> 
                                             <b>Qty:</b>
                                             <div class="product__details-count mb-10 mr-10">
                                                <span class="cart-minus"  (click)="addTocartDecrement(productDetails.category_id, productDetails)" >
                                                   <!--<i class="far fa-minus"></i>-->
                                                   <svg xmlns="http://www.w3.org/2000/svg" width="14.188" height="4" viewBox="0 0 14.188 4">
                                                      <path id="Path_3528" data-name="Path 3528" d="M7.5,18H17.688" transform="translate(-5.5 -16)" fill="none" stroke="red" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"/>
                                                    </svg>
                                                    
                                                
                                                </span>
                                                <input class="tp-cart-input" type="text" value="{{itemQty}}">
                                                <span class="cart-plus"  (click)="openProduct(productDetails.category_id, productDetails)" >
                                                <!--<i style="font-weight: 900 !important;" class="far fa-plus"></i>-->
                                                <svg xmlns="http://www.w3.org/2000/svg" width="14.187" height="14.187" viewBox="0 0 14.187 14.187">
                                                   <g id="Group_1318" data-name="Group 1318" transform="translate(2 2)">
                                                     <path id="Path_3461" data-name="Path 3461" d="M18,7.5V17.687" transform="translate(-12.906 -7.5)" fill="none" stroke="#3f8e02" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"/>
                                                     <path id="Path_3462" data-name="Path 3462" d="M7.5,18H17.687" transform="translate(-7.5 -12.906)" fill="none" stroke="#3f8e02" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"/>
                                                   </g>
                                                 </svg>
                                                 
                                             </span>
                                             </div>
                                             <!--<div class="product__details-btn mb-10">
                                                <a href="javascript:void(0);" (click)="openProduct(productDetails.category, productDetails, itemQty)">add to cart</a>
                                             </div>-->
                                          </div>

                                       </div>
                                       <!--<div class="product__details-stock mb-25">
                                          <ul>                                             
                                             <li>Categories: <span>Vegetables, Meat & Eggs, Fruit Drink </span></li>
                                          </ul>
                                       </div>-->
                                      
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>

                     </div>
                  </div>

               </div>
            </div>
         </section>
         <!-- shop-details-area-end -->
    <!-- END: Cart Page Section -->

    
        <!-- feature-area-start -->
        <app-feature-area></app-feature-area>
        <!-- feature-area-end -->
