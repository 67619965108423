
        <!-- BEGIN: Page Banner Section -->
        <section class="pageBannerSection">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="pageBannerContent text-center">
                            <h2>Our Product</h2>
                            <div class="pageBannerPath">
                                <a routerLink="/">Home</a>&nbsp;&nbsp; / &nbsp;&nbsp;<span>Our Product</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- END: Page Banner Section -->

        <!-- BEGIN: Shop Page Section -->
        <section class="shopPageSection shopPageHasSidebar">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-xl-3">
                        <div class="shopSidebar">
                            <aside class="widget">
                                <h3 class="widgetTitle">Categories</h3>
                                <ul>

                                    <li class="menu-item-has-children" *ngFor="let cat of myClonedcategory " >
                                        <!--<a  (click)="changeCategory(cat.id)">{{cat.name}}</a>-->
                                        <a  [ngClass]="{'active': cat.id == selected_category_id}" >{{cat.name}}</a>
                                        <ul *ngIf="cat.subCat.length > 0" class="subcat" [ngStyle]="{'display': (cat.id == selected_category_id) ? 'block' : ''}" >
                                            <li *ngFor="let subCat of cat.subCat" id="anchor{{cat.id}}_{{subCat.id}}"   >
                                                <a  (click)="filterbysubcategory(cat.id, subCat.id);" [ngClass]="{'active': subCat.id == selected_subcategory}">{{subCat.name}}</a>
                                            </li>
                                            
                                        </ul>
                                    </li>

                                </ul>

                                <ngx-skeleton-loader *ngIf="myClonedcategoryLoading" count="4" animation="pulse"
                                [theme]="{
                                    width: '200px',
                                    height: '20px'
                                }"
                               ></ngx-skeleton-loader>
                               

                            </aside>

                        </div>
                    </div>
                   
                    <div class="col-lg-8 col-xl-9">
                        <div class="row shopProductRow">
                            <div class="col-sm-6 col-lg-4 col-xl-4" *ngFor="let dish of MenuDetails | filterMenu: 'subcategory' : selected_subcategory | paginate : {
                                itemsPerPage: itemsPerPage,
                                currentPage: page,
                                totalItems: totalItems
                              }">
                                <!-- tpproduct -->
                                <div class="tpproduct p-relative" >
                                    <div class="tpproduct__thumb p-relative text-center" *ngIf="dish.img != ''">
                                        <a routerLink="/product-details/{{dish.id}}"><img src="{{dish.img}}" alt=""></a>
                                    </div>
                                    <div class="tpproduct__thumb p-relative text-center" *ngIf="dish.img == ''">
                                        <a routerLink="/product-details/{{dish.id}}"><img src="./assets/images/no_image.png" alt=""></a>
                                    </div>
                                    <div class="tpproduct__content">

                                        <h4 class="tpproduct__title">
                                            <a routerLink="/product-details/{{dish.id}}">{{dish.name}}</a>
                                        </h4>
                                       <span class="tpproduct__content-weight">
                                        <!--<p>{{dish.description}}</p>-->
                                        <div *ngIf="dish.description != ''" [innerHTML] = "dish.description"></div>
                                        </span>
                                        <div class="tpproduct__price">
                                            <span>{{businessDetails.currency_symbol}}{{dish.price}}</span>
                                        </div>
                                    </div>


                                    <div class="cartbox" >
                                        <button class="plusbtn" [ngClass]="cartDishArr.indexOf(dish.id) > -1 ? 'hide_div' : 'show_div'" href="javascript:void(0);" (click)="openProduct(selected_category_id, dish)"
                                        *ngIf="businessDetails.open == true  && businessDetails.bclosed == false">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                                            <g id="Union_1" data-name="Union 1" transform="translate(1764 326)" fill="#fff">
                                                <path d="M -1757.499877929688 -314.5001220703125 L -1758.499633789062 -314.5001220703125 L -1758.499633789062 -318.9996032714844 L -1758.499633789062 -319.4996032714844 L -1758.999633789062 -319.4996032714844 L -1763.5 -319.4996032714844 L -1763.5 -320.5003051757812 L -1758.999633789062 -320.5003051757812 L -1758.499633789062 -320.5003051757812 L -1758.499633789062 -321.0003051757812 L -1758.499633789062 -325.4998168945312 L -1757.499877929688 -325.4998168945312 L -1757.499877929688 -321.0003051757812 L -1757.499877929688 -320.5003051757812 L -1756.999877929688 -320.5003051757812 L -1752.500366210938 -320.5003051757812 L -1752.500366210938 -319.4996032714844 L -1756.999877929688 -319.4996032714844 L -1757.499877929688 -319.4996032714844 L -1757.499877929688 -318.9996032714844 L -1757.499877929688 -314.5001220703125 Z" stroke="none"/>
                                                <path d="M -1753.000366210938 -319.9996032714844 L -1753.000366210938 -320.0003051757812 L -1763 -319.9996032714844 L -1753.000366210938 -319.9996032714844 M -1756.999877929688 -314.0001220703125 L -1758.999633789062 -314.0001220703125 L -1758.999633789062 -318.9996032714844 L -1764 -318.9996032714844 L -1764 -321.0003051757812 L -1758.999633789062 -321.0003051757812 L -1758.999633789062 -325.9998168945312 L -1756.999877929688 -325.9998168945312 L -1756.999877929688 -321.0003051757812 L -1752.000366210938 -321.0003051757812 L -1752.000366210938 -318.9996032714844 L -1756.999877929688 -318.9996032714844 L -1756.999877929688 -314.0001220703125 Z" stroke="none" fill="#fff"/>
                                            </g>
                                            </svg>
                                        </button>

                                        <div class="cartboxin" *ngIf="cartDishArr.indexOf(dish.id) > -1" [ngClass]="cartDishArr.indexOf(dish.id) > -1 ? 'show_div_flex' : 'hide_div'">
                                            <button class="cartminus" (click)="addTocartDecrement(selected_category_id, dish)">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="2" viewBox="0 0 12 2">
                                                    <g id="Rectangle_364" data-name="Rectangle 364" transform="translate(0 2) rotate(-90)" fill="#fff" stroke="#fff" stroke-width="1">
                                                    <rect width="2" height="12" stroke="none"/>
                                                    <rect x="0.5" y="0.5" width="1" height="11" fill="none"/>
                                                    </g>
                                                </svg>
                                                
                                            </button>
                                            <input type="text" class="cartvalue" readonly value="{{cartDishFullArr[cartDishArr.indexOf(dish.id)].quantity}}">
                                            <button class="cartplus" (click)="openProduct(selected_category_id, dish)">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                                                    <g id="Union_1" data-name="Union 1" transform="translate(1764 326)" fill="#fff">
                                                        <path d="M -1757.499877929688 -314.5001220703125 L -1758.499633789062 -314.5001220703125 L -1758.499633789062 -318.9996032714844 L -1758.499633789062 -319.4996032714844 L -1758.999633789062 -319.4996032714844 L -1763.5 -319.4996032714844 L -1763.5 -320.5003051757812 L -1758.999633789062 -320.5003051757812 L -1758.499633789062 -320.5003051757812 L -1758.499633789062 -321.0003051757812 L -1758.499633789062 -325.4998168945312 L -1757.499877929688 -325.4998168945312 L -1757.499877929688 -321.0003051757812 L -1757.499877929688 -320.5003051757812 L -1756.999877929688 -320.5003051757812 L -1752.500366210938 -320.5003051757812 L -1752.500366210938 -319.4996032714844 L -1756.999877929688 -319.4996032714844 L -1757.499877929688 -319.4996032714844 L -1757.499877929688 -318.9996032714844 L -1757.499877929688 -314.5001220703125 Z" stroke="none"/>
                                                        <path d="M -1753.000366210938 -319.9996032714844 L -1753.000366210938 -320.0003051757812 L -1763 -319.9996032714844 L -1753.000366210938 -319.9996032714844 M -1756.999877929688 -314.0001220703125 L -1758.999633789062 -314.0001220703125 L -1758.999633789062 -318.9996032714844 L -1764 -318.9996032714844 L -1764 -321.0003051757812 L -1758.999633789062 -321.0003051757812 L -1758.999633789062 -325.9998168945312 L -1756.999877929688 -325.9998168945312 L -1756.999877929688 -321.0003051757812 L -1752.000366210938 -321.0003051757812 L -1752.000366210938 -318.9996032714844 L -1756.999877929688 -318.9996032714844 L -1756.999877929688 -314.0001220703125 Z" stroke="none" fill="#fff"/>
                                                    </g>
                                                    </svg>
                                            </button>
                                        </div>

                                    </div>
                                </div>
                                <!-- tpproduct -->
                            </div>

                            <div *ngIf="MenuDetails.length == 0 && menuloading == 1">
                                No Record Found
                            </div>


                            
                            <div *ngIf="MenuDetailsLoading">
                                <ngx-skeleton-loader  count="3" appearance="circle"
                                [theme]="{
                                    width: '30%',
                                    'border-radius': '10px',
                                    height: '300px',
                                    'background-color': '#e3e3e3'
                                }"
                            ></ngx-skeleton-loader>
                            <ngx-skeleton-loader  count="3" appearance="circle"
                                [theme]="{
                                    width: '30%',
                                    'border-radius': '10px',
                                    height: '300px',
                                    'background-color': '#e3e3e3'
                                }"
                            ></ngx-skeleton-loader>

                            </div>
                                
                                      


                        </div>

                        <div class="row shopPaginationRow">
                            <div class="col-lg-12 text-center">
                                
                                    <pagination-controls  *ngIf="MenuDetails.length > 0 && menuloading == 1" class="pagi" (pageChange)="pageChange((page = $event))" ></pagination-controls>
                                
                            </div>    
                        </div>
                        <!--<div class="row shopPaginationRow">
                            <div class="col-lg-12 text-center">
                                <div class="shopPagination">
                                    <a href="javascript:void(0);"><i class="fa-solid fa-arrow-left"></i></a>
                                    <span class="current">1</span>
                                    <a href="javascript:void(0);">2</a>
                                    <a href="javascript:void(0);">3</a>
                                    <a href="javascript:void(0);"><i class="fa-solid fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>-->

                    </div>
                </div>
            </div>
        </section>
        <!-- END: Shop Page Section -->

        <app-custom-alert *ngIf="custom_alert_show"
        [header]="custom_alert_header"
        [btnText]="custom_alert_btnText"
        [text]="custom_alert_text"
        [type]="custom_alert_type"
        [show]="custom_alert_show"
        (onClosePressFunction)="onCloseAlert()"
        [pressCloseOnOutsideClick]="pressCloseOnOutsideClick"
        [alertStyles]="alertStyles"
        [headerStyles]="headerStyles"
        [textStyles]="textStyles"
        [buttonStyles]="buttonStyles"
        ></app-custom-alert>

         <!-- feature-area-start -->
        <app-feature-area></app-feature-area>
        <!-- feature-area-end -->