import { Component, OnInit, OnDestroy, ViewRef, ChangeDetectorRef } from '@angular/core';
import { CommonService } from '../common.service';
import { DataSharingService } from '../data-share.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Globals } from '../globals';
import { ApiService } from '../api.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {

  orderId: any;
  public globals: Globals;
  public language: any = null;
  public CartDish: any;
  public businessDetails: any = null;
  public cartFee: any;
  public CartSubTotal: any;

  public businessId: any;
  public categoryDetails: any;
  public MenuDetails: any;
  public orderType: any;
  public SubTotal: any;
  public discountInfo: any;
  public discount: any;
  public tips: any;
  public txnId: any;

  constructor(
    private http: HttpClient,
    private comservice: CommonService,
    private router: Router,
    private dataSharingService: DataSharingService,
    public apiservice: ApiService,
    globals: Globals,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
  ) { 
    this.orderId = this.comservice.get('orderId');
    this.globals = globals;
    this.language = this.globals.LANGUAGE[this.globals?.LANG_ID as keyof typeof this.globals.LANGUAGE];

    let cart = this.comservice.get('cartDetails');
    if(cart.length > 0){
      this.CartDish = cart;
      this.businessDetails = this.comservice.get('businessDetails');

    }
  }

  ngOnInit(): void {

    this.orderId = this.comservice.get('orderId');

    let cart = this.comservice.get('cartDetails');
    if(cart.length > 0){
      this.CartDish = cart;
      this.businessDetails = this.comservice.get('businessDetails');

    }

    if (this.orderId == undefined  || this.orderId == '') {
      this.router.navigate(['/']);
    }else{
      this.clearStorage();
    }

    this.CalTotal();

    this.discount = 0.00;
    this.tips = 0.00;

    this.CartSubTotal = 0.00;
    for (let i = 0; i < this.CartDish.length; i++) {
      for (let j = 0; j < this.CartDish[i].dish.length; j++) {
        for (let k = 0; k < this.CartDish[i].dish[j].data.length; k++) {
          if (this.CartDish[i].dish[j].data[k].total) {
            this.CartSubTotal = parseFloat(this.CartSubTotal) + parseFloat(this.CartDish[i].dish[j].data[k].total);
            this.CartSubTotal = parseFloat(this.CartSubTotal).toFixed(2);
          }
        }
      }
    }

    setTimeout(() => {
      this.language = this.globals.LANGUAGE[this.globals?.LANG_ID as keyof typeof this.globals.LANGUAGE];  
    }, 1500);
  }

  clearStorage() {
    //this.comservice.clear();
    setTimeout(() => {
      if (this.cdRef && !(this.cdRef as ViewRef).destroyed) {
        this.dataSharingService.isCartAdded.next(true);
        this.cdRef.detectChanges();
      }
    });

    this.comservice.remove('cartDetails');
    this.comservice.deleteService('cartDetails');
   
    this.comservice.deleteService('categoryDetails');
    this.comservice.deleteService('myClonedcategoryDetails');
    this.comservice.deleteService('MenuDetails');
    
  }
  ngOnDestroy() {
    this.router.navigate(['/']);
    location.reload();
  }

  
  CalTotal() {
    let SubTotalDiscount: any;
    SubTotalDiscount = 0.00;
    for (let i = 0; i < this.CartDish.length; i++) {
      for (let j = 0; j < this.CartDish[i].dish.length; j++) {
        for (let k = 0; k < this.CartDish[i].dish[j].data.length; k++) {
          if (this.CartDish[i].dish[j].data[k].total) {
            SubTotalDiscount = parseFloat(SubTotalDiscount) + parseFloat(this.CartDish[i].dish[j].data[k].total);
            SubTotalDiscount = parseFloat(SubTotalDiscount).toFixed(2);
          }
        }
      }
    }
   
    this.CalDiscount();
     
  }
  CalDiscount() {
    this.cartFee = new Array();
    this.SubTotal = 0.00;
    this.discount = 0.00;

    for (let i = 0; i < this.CartDish.length; i++) {
      for (let j = 0; j < this.CartDish[i].dish.length; j++) {
        for (let k = 0; k < this.CartDish[i].dish[j].data.length; k++) {
          if (this.CartDish[i].dish[j].data[k].total) {
            this.SubTotal = parseFloat(this.SubTotal) + parseFloat(this.CartDish[i].dish[j].data[k].total);
            this.SubTotal = parseFloat(this.SubTotal).toFixed(2);
          }
        }
      }
      let firstOffer: any;
      firstOffer = 0.00;
      /*if (this.businessDetails.firstorderstatus === '1' && this.businessDetails.ordercountstatus) {
        if (parseFloat(this.SubTotal) >= parseFloat(this.businessDetails.firstordervalue)) {
          firstOffer = parseFloat(this.SubTotal) * this.businessDetails.firstorderoffer * 0.01;
          firstOffer = parseFloat(firstOffer).toFixed(2);
        }
      }*/
      this.CartDish[i].details.delivery = 0.00;

      /*if (this.businessDetails.freedeliverystatus == 1) {
        if (parseFloat(this.SubTotal) >= parseFloat(this.businessDetails.freedeliveryvalue)) {
          this.CartDish[i].details.delivery = 0.00;
        }
       }*/
     

      let extraminimum: any;
      extraminimum = 0.00;
     /* if (this.businessDetails.requiredminimumstatus === '1') {
        if (parseFloat(this.SubTotal) < parseFloat(this.businessDetails.minimumfee)) {
          extraminimum = parseFloat(this.businessDetails.minimumfee) - parseFloat(this.SubTotal);
          extraminimum = parseFloat(extraminimum).toFixed(2);
        }
      }*/

      let subtotal: any;
      subtotal = parseFloat(this.SubTotal) - parseFloat(this.discount);
      subtotal = parseFloat(subtotal).toFixed(2);

      let taxprice: any;
      taxprice = parseFloat(subtotal) * Number(this.CartDish[i].details.tax * 0.01);
      taxprice = parseFloat(taxprice).toFixed(2);

      let servicefeeprice: any;
      servicefeeprice = parseFloat(subtotal) + parseFloat(this.CartDish[i].details.delivery);
      servicefeeprice = servicefeeprice * this.CartDish[i].details.servicefee * 0.01;
      servicefeeprice = parseFloat(servicefeeprice).toFixed(2);

      let total: any;
      
      if (this.CartDish[i].details.taxtype == 0) {
        total = parseFloat(this.SubTotal) - parseFloat(this.discount) + parseFloat(this.CartDish[i].details.delivery) + parseFloat(taxprice) + parseFloat(servicefeeprice);
        total = parseFloat(total).toFixed(2);

        console.log("SubTotal:" + this.SubTotal + "-" + "Discount:" + this.discount + "-" + "delivery:" + this.CartDish[i].details.delivery + "-" + "Taxprice:" + taxprice + "- service" + servicefeeprice);
        console.log(total);
      } else {
        total = parseFloat(this.SubTotal) - parseFloat(this.discount) + parseFloat(this.CartDish[i].details.delivery) + parseFloat(servicefeeprice);
        total = parseFloat(total).toFixed(2);
      }


     /* if (this.businessDetails.requiredminimumstatus === '1') {
        total = parseFloat(total) + parseFloat(extraminimum);
        total = parseFloat(total).toFixed(2);
      }

      if (this.businessDetails.firstorderstatus === '1' && this.businessDetails.ordercountstatus) {
        total = parseFloat(total) - parseFloat(firstOffer);
        total = parseFloat(total).toFixed(2);
      }*/

      let fee: any;
      fee = {
        'id': this.CartDish[i].id,
        'name': this.CartDish[i].details.name,
        'subtotal': this.SubTotal,
        'tax': this.CartDish[i].details.tax,
        'taxtype': this.CartDish[i].details.taxtype,
        'taxprice': taxprice,
        'deliverycost': this.CartDish[i].details.delivery,
        'extraminimum': extraminimum,
        'firstoffer': firstOffer,
        'servicefee': this.CartDish[i].details.servicefee,
        'servicefeeprice': servicefeeprice,
        'discount': this.discount,
        'tips': 0,
        'total': total
      };
      
      this.cartFee.push(fee);

      let uDetails: any;
      uDetails = this.comservice.getFromLocal('user-details');

    }
  }

}
