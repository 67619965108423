import { Component, OnInit } from '@angular/core';
import { CommonService } from '../common.service';
import { DataSharingService } from '../data-share.service';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { Globals } from '../globals';
import { ApiService } from '../api.service';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';

import { ActivatedRoute } from '@angular/router';

import * as $ from 'jquery' 

import {NgxPaginationModule} from 'ngx-pagination'; 
import { FilterPipe, OrderFilterPipe, FilterMenuPipe } from '../filter.pipe';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
  
  public globals: Globals;
  public language: any = null;
  isloading: any = 0;

  options = { autoHide: true, scrollbarMinSize: 100 };
  public businessId: any;
  public businessDetails: any;
  public categoryDetails: any;
  public MenuDetails: any = [];
  public userdetails: any;
  public FavBusiness: any;
  public cartDetails: any;
  public orderType: any = '1';
  public CartDish: any = [];
  public CartSubTotal: any;
  public PendingMinimumFee: any;
  public PendingFreeDelivery = false;
  public PendingFreeDeliveryValue: any;
  public myClonedcategoryDetails: any;
  public myClonedcategory: any;
  public from_order: any;
  public indexcart: any;
  public searchText: any;
  public tabscroll: any;
  public preorderDetails: any = {
    preorder: false,
    preorderDate: '',
    preorderTime: '',
    preorderMenu: 0
  };
  public menuDisplay = true;
  public productDisplay = false;
  public suggestProduct: any = [];
  public suggestProductArray: any = [];
  public catLength: Number = 0;
  public catLimit: Number = 0;
  public scroll = 0;
  public appType: any;
  public cartQuantity = 0;
  public stickyheight: any;

  public selectable = false;
  public removable = true;
  public dish: any;
  public dishTotal: any;
  public from_order_pos: any;

  public carImage = './assets/images/car.png';

  public buyOneGetOneProduct: any = [];


  page: number = 1;
  itemsPerPage = 24;
  totalItems : any = 0;

  selected_category_id: number = 0;
  selected_category_type = 1;
  selected_subcategory = "";
  subcategoryfilter: any = [];
  menuloading: any = 0;
  search_keyword: any = '';

  /*paginate : any = {
              itemsPerPage: 12,
              currentPage: 1,
              totalItems: 30
            }*/

  cartDishArr: any = [];
  cartDishFullArr: any = [];     
  
  myClonedcategoryLoading = true;
  MenuDetailsLoading = true;


  constructor(
    private http: HttpClient,
    private comservice: CommonService,
    private router: Router,
    private dataSharingService: DataSharingService,
    public apiservice: ApiService,
    globals: Globals,
    private datePipe : DatePipe,
    private route: ActivatedRoute,
  ) { 
    this.globals = globals;
    this.language = this.globals.LANGUAGE[this.globals?.LANG_ID as keyof typeof this.globals.LANGUAGE];

    this.businessDetails = this.comservice.get('businessDetails');

    this.dataSharingService.isCartAdded.subscribe(value => {
      let cart = this.comservice.get('cartDetails');
      if(cart.length > 0){
        this.CartDish = cart;

        this.cartDishFullArr = this.CartDish[0].dish;
        this.cartDishArr = [];
        let cart_val;
        for (cart_val of this.CartDish) {
          let dish_val;
          for (dish_val of cart_val.dish) {
            this.cartDishArr.push(dish_val.id);
          }
        }
      }
      if (value === true) {
        this.CartDish = this.comservice.get('cartDetails');
        this.cartQuantity = 0;
        let cart;
        for (cart of this.CartDish) {
          let dish;
          for (dish of cart.dish) {
            this.cartQuantity = this.cartQuantity + dish.quantity;
          }
        }
        this.calCartTotal();
        this.dataSharingService.isCartAdded.next(false);
      }
    });

    this.router.events.subscribe((event: Event) => {
        if (event instanceof NavigationStart) {
            // Show progress spinner or progress bar
            console.log('Route change detected');
            
        }

        if (event instanceof NavigationEnd) {
            // Hide progress spinner or progress bar
            //this.currentRoute = event.url;          
            console.log(event);

            this.route.queryParams
              .subscribe(params => {

                if(params['cat']){
                  this.selected_category_id = params['cat'];
                }
                if(params['keyword']){
                  this.search_keyword = params['keyword'];
                }

                this.header_search(this.selected_category_id);
              
              }
            );
        }

        if (event instanceof NavigationError) {
            // Hide progress spinner or progress bar

            // Present error to user
            console.log(event.error);
        }

    });

   
  }


  custom_alert_header: any = "Contacts";
  custom_alert_btnText: any = "Accept"; 
  custom_alert_text: any = []; 
  custom_alert_type: any = "error"; 
  custom_alert_show: any = false; 
  pressCloseOnOutsideClick: any = true;
  alertStyles:any = "";
  headerStyles:any = "";
  textStyles:any = "";
  buttonStyles:any = "";

    
onCloseAlert(){
  this.custom_alert_show = false;
  this.custom_alert_type = null;
  this.custom_alert_text = [];

}

onShowAlert(type: any,msg: any){
  this.custom_alert_type = type;
  this.custom_alert_text = msg;
  this.custom_alert_show = true;
}

  ngOnInit(): void {
    setTimeout(() => {
      this.language = this.globals.LANGUAGE[this.globals?.LANG_ID as keyof typeof this.globals.LANGUAGE];  
    }, 2000);

    this.route.queryParams
      .subscribe(params => {

        if(params['cat']){
          this.selected_category_id = params['cat'];
        }
        if(params['keyword']){
          this.search_keyword = params['keyword'];
        }
      }
    );

    $(function(){
      setTimeout(function(){
          $('.shopSidebar ul li.menu-item-has-children > a').on('click', function(e){
            e.preventDefault();
            $(this).siblings('ul').slideToggle();
            $(this).parent('li.menu-item-has-children').toggleClass('active');
          });
        }, 3000);   
    });

    this.from_order = false;
    this.comservice.scrollTop();
    this.indexcart = new Object();
    this.businessId = this.globals.BUSINESS_ID;
    this.businessDetails = this.comservice.getService('businessDetails');

      this.userdetails = this.comservice.getFromLocal('user-details');
      let uid = -1;
      if (this.userdetails) {
        if (this.userdetails.id) {
          uid = this.userdetails.id;
        }
      }
      this.apiservice.getBusiness(this.globals.BUSINESS_SLUG, uid).subscribe((response: any) => {
            if (Object.keys(response).length === 0) {
              this.router.navigate(['/404']);
            } else {
              this.comservice.setService('businessID', response.id);
              this.businessId  = response.id;

              let is_img;
              is_img = JSON.parse(response.is_img);
              if (is_img.is_img === 1) {
                response.logo = is_img.data.secure_url;
              } else {
                response.logo = './assets/images/business_logo.png';
              }
              let is_banner;
              is_banner = JSON.parse(response.is_banner);
              if (is_banner.is_img === 1) {
                response.banner = is_banner.data.secure_url;
              } else {
                response.banner = './assets/images/business_banner.png';
              }
              this.comservice.set('businessDetails', response);
              this.businessDetails = response;
  
              let preorderDetails;
              preorderDetails = this.comservice.get('preorderDetails');
              if (preorderDetails === undefined || preorderDetails === '') {
                preorderDetails = {
                  preorder: false,
                  preorderDate: '',
                  preorderTime: '',
                  preorderMenu: 0
                };
              } else {
                if (preorderDetails.preorderBusiness !== response.id) {
                  preorderDetails = {
                    preorder: false,
                    preorderDate: '',
                    preorderTime: '',
                    preorderMenu: 0,
                    preorderMenuSchedule: '',
                    preorderDateString: ''
                  };
                  this.comservice.set('preorderDetails', preorderDetails);
                }
              }
  
              let orderTypeStatus;
              orderTypeStatus = this.comservice.get('orderType');
      
              let orderType;
              if (orderTypeStatus === undefined || orderTypeStatus === '') {
                orderType = '1';
              } else {
                orderType = orderTypeStatus;
              }

              let valDetails;
              valDetails = {
                minimum: response.minimumfee,
                delivery: 0
              };
              this.comservice.set('deliveryDetails', valDetails);
      
              this.apiservice.getbusinessmenu_category(response.id).subscribe((responseMenu: any) => {
                    this.comservice.setService('categoryDetails', responseMenu.category);
                    this.myClonedcategory = responseMenu.category;
                    this.myClonedcategoryLoading = false;
                    if(this.selected_category_id == 0){
                      this.selected_category_id = responseMenu.category[0].id;
                    }

              });

              this.apiservice.getbusinessmenuByCategory(response.id, this.page, this.selected_category_id, this.selected_category_type, this.itemsPerPage, this.search_keyword).subscribe((responseMenu: any) => {
                this.comservice.setService('MenuDetails', responseMenu.menu);
                this.totalItems = responseMenu.total_menu;

                let MenuDetails: any;
                MenuDetails = responseMenu.menu;
                this.MenuDetailsLoading = false;
                

                this.menuloading = 1;
                
                if (Object.keys(MenuDetails).length === 0) {
                  this.menuDisplay = false;
                  this.productDisplay = false;
                  /*this.openBusiness();
                  this.snackBar.open(this.language.NOT_ACTIVE_MENU, this.language.TOAST_OK, {
                    duration: 3000,
                    horizontalPosition: 'center',
                    verticalPosition: 'top'
                  });*/
                } else {
                 
                  this.MenuDetails = MenuDetails;
                  this.calCartTotal();
                }

          });

        }
      });

  }

  header_search(category_id:any){

    this.menuloading = 0;

    this.selected_subcategory ='';

    this.selected_category_id = category_id;

    this.apiservice.getbusinessmenuByCategory(this.businessId, this.page, this.selected_category_id, this.selected_category_type, this.itemsPerPage, this.search_keyword).subscribe((responseMenu: any) => {
      this.comservice.setService('MenuDetails', responseMenu.menu);
      this.totalItems = responseMenu.total_menu;

      let MenuDetails: any;
      MenuDetails = responseMenu.menu;

      this.MenuDetails = MenuDetails;

      this.menuloading = 1; 
      
   });

}

  changeCategory(category_id:any){

      this.menuloading = 0;

      this.selected_subcategory ='';

      this.selected_category_id = category_id;

       this.router.navigate(
        [], 
        {
          queryParams: { cat: category_id }, 
          queryParamsHandling: 'merge', // remove to replace all query params by provided
        });

      this.apiservice.getbusinessmenuByCategory(this.businessId, this.page, this.selected_category_id, this.selected_category_type, this.itemsPerPage, this.search_keyword).subscribe((responseMenu: any) => {
        this.comservice.setService('MenuDetails', responseMenu.menu);
        this.totalItems = responseMenu.total_menu;

        let MenuDetails: any;
        MenuDetails = responseMenu.menu;

        this.MenuDetails = MenuDetails;

        this.menuloading = 1; 
        
     });

  }

  filterbysubcategory(cat_id: any, subcat_id: any){

    this.MenuDetailsLoading = true;
   
    this.selected_category_id = cat_id;
    this.selected_subcategory = subcat_id;

      this.menuloading = 0;

       /*this.router.navigate(
        [], 
        {
          queryParams: { cat: cat_id }, 
          queryParamsHandling: 'merge', // remove to replace all query params by provided
        });*/

      this.apiservice.getbusinessmenuBySubCategory(this.businessId, this.page, cat_id, subcat_id, this.itemsPerPage, this.search_keyword).subscribe((responseMenu: any) => {
        this.comservice.setService('MenuDetails', responseMenu.menu);
        this.MenuDetailsLoading = false;
        this.totalItems = responseMenu.total_menu;

        let MenuDetails: any;
        MenuDetails = responseMenu.menu;

        this.MenuDetails = MenuDetails;

        this.menuloading = 1; 
        
     });

  }

  pageChange(page: any){

    this.page = page;

    this.menuloading = 0;

    this.apiservice.getbusinessmenuByCategory(this.businessId, this.page, this.selected_category_id, this.selected_category_type, this.itemsPerPage, this.search_keyword).subscribe((responseMenu: any) => {
          this.comservice.setService('MenuDetails', responseMenu.menu);
          this.totalItems = responseMenu.total_menu;
          this.selected_category_id = responseMenu.category_id;

          let MenuDetails: any;
          MenuDetails = responseMenu.menu;
          this.MenuDetails = MenuDetails;
          this.menuloading = 1;

    });
  }

  cartRemove() {
    this.CartDish = new Array();
    this.comservice.set('cartDetails', this.CartDish);
    this.orderType = '0';
    this.comservice.set('orderType', this.orderType);
    this.preorderDetails = {
      preorder: false,
      preorderDate: '',
      preorderTime: '',
      preorderMenu: 0,
      preorderMenuSchedule: '',
      preorderDateString: ''
    };
    this.comservice.set('preorderDetails', this.preorderDetails);
    this.dataSharingService.isCartAdded.next(true);
  }

  CheckDataDecrement(cartdata: any){
    if(cartdata.length > 0){
      for (let i = 0; i < cartdata.length; i++) {
        if (cartdata[i].sets.length === 0) {
          return i;
        }
      }
      return -1;
    }else{
      return -1
    }

  }

  addTocartDecrement(catid:any, dish: any) {

    this.dish = dish;       

    let index = this.CheckBusinessAddtoCart(this.businessDetails.id);      
   
    let dishindex;
    dishindex = this.CheckAddtoCart(this.dish.id, index);
    let dishdataindex = this.CheckDataDecrement(this.CartDish[index].dish[dishindex].data);
    
    this.CartDish[index].dish[dishindex].quantity = this.CartDish[index].dish[dishindex].quantity - 1;
    this.CartDish[index].dish[dishindex].data[dishdataindex].quantity = this.CartDish[index].dish[dishindex].data[dishdataindex].quantity - 1;
    this.CartDish[index].dish[dishindex].data[dishdataindex].total = parseFloat(this.CartDish[index].dish[dishindex].data[dishdataindex].totalprice) * parseInt(this.CartDish[index].dish[dishindex].data[dishdataindex].quantity, 10);
    this.CartDish[index].dish[dishindex].data[dishdataindex].total = parseFloat(this.CartDish[index].dish[dishindex].data[dishdataindex].total).toFixed(2);
    if (this.CartDish[index].dish[dishindex].data[dishdataindex].quantity == 0) {
      this.CartDish[index].dish[dishindex].data.splice(dishdataindex, 1);
      if (this.CartDish[index].dish[dishindex].data.length == 0) {
        this.CartDish[index].dish.splice(dishindex, 1);
      }
      if (this.CartDish[index].dish.length == 0) {
        this.CartDish.splice(index, 1);
      }
      this.comservice.set('cartDetails', this.CartDish);
      this.dataSharingService.isCartAdded.next(true);
    } else {
      this.comservice.set('cartDetails', this.CartDish);
      this.dataSharingService.isCartAdded.next(true);
    }

  
  if (this.CartDish.length > 0 ) {
    this.comservice.set('cartDetails', this.CartDish);
    this.dataSharingService.isCartAdded.next(true);

    this.cartDishFullArr = this.CartDish[0].dish;
    this.cartDishArr = [];
    let cart_val;
    for (cart_val of this.CartDish) {
      let dish_val;
      for (dish_val of cart_val.dish) {
        this.cartDishArr.push(dish_val.id);
      }
    }

    
  }else{
    this.cartDishArr = [];
  }
  
}


  openProduct(catid:any, dish: any) {
    
    /*if (dish.dishstatus != 1) {
      const all_error_msg: any = [];
      all_error_msg.push("Item Close so can not place an order");
      this.onShowAlert('error',all_error_msg);
    }else{
      this.addToCart(dish);
    }*/

    this.addToCart(dish);
   
  }
  addToCart(dishTemp: any) {
    this.dish = dishTemp;
    this.dishTotal = this.dish.price;

    let bDeatilsCloned;
    bDeatilsCloned = JSON.stringify(this.businessDetails);
    let bDeatils;
    bDeatils = JSON.parse(bDeatilsCloned);
    bDeatils.street = JSON.parse(bDeatils.street);
    bDeatils.type = JSON.parse(bDeatils.type);
    bDeatils.location = JSON.parse(bDeatils.location);
    bDeatils.is_img = JSON.parse(bDeatils.is_img);
    bDeatils.is_banner = JSON.parse(bDeatils.is_banner);
    bDeatils.cuisine = JSON.parse(bDeatils.cuisine);

    let business;
    business = {
      id: this.businessDetails.id,
      details: bDeatils,
      dish: new Array()
    };

    let dish;
    dish = {
      'id': this.dish.id,
      'category': this.dish.category,
      'quantity': 1,
      'data' : new Array()
    };

    let parent_index = this.CheckBusinessAddtoCart(this.businessDetails.id);
    if (parent_index === -1) {
      this.CartDish.push(business);
    }

    let activeIngredients;
    activeIngredients = new Array();

    let data;
    data = {
      'id': this.dish.id,
      'name': this.dish.name,
      'price': this.dish.price,
      'delprice': this.dish.delprice,
      'pickprice': this.dish.pickprice,
      'notes': this.dish.notes,
      'totalprice': this.dishTotal,
      'total': this.dishTotal,
      'is_img': this.dish.is_img,
      'img': this.dish.img,
      'spicy': this.dish.spicy,
      'spicyquantity': this.dish.spicyquantity,
      'veg': this.dish.veg,
      'nonveg': this.dish.nonveg,
      'ingredients': this.dish.ingredients,
      'ingredientsarray': this.dish.ingredientsarray,
      'activeIngredients': activeIngredients,
      'quantity': 1,
      'sets': new Array(),
      'relation': {
        'options': new Array(),
        'choices': new Array()
      },
      'free': {}
    };
    
      parent_index = this.CheckBusinessAddtoCart(this.businessDetails.id);
      let index;
      index = this.CheckAddtoCart(data.id, parent_index);
      if (index === -1) {
        dish.data.push(data);
        this.CartDish[parent_index].dish.push(dish);
      } else {
        this.CartDish[parent_index].dish[index].quantity = parseInt(this.CartDish[parent_index].dish[index].quantity, 10) + 1;
        if (data.sets.length === 0) {
          let index_data;
          index_data = this.CheckData(this.CartDish[parent_index].dish[index].data, data);
          if (index_data === -1) {
            this.CartDish[parent_index].dish[index].data.push(data);
          } else {
            this.CartDish[parent_index].dish[index].data[index_data].notes = this.dish.notes;
            this.CartDish[parent_index].dish[index].data[index_data].quantity = parseInt(this.CartDish[parent_index].dish[index].data[index_data].quantity, 10) + 1;
            this.CartDish[parent_index].dish[index].data[index_data].total = parseFloat(this.CartDish[parent_index].dish[index].data[index_data].totalprice) * parseInt(this.CartDish[parent_index].dish[index].data[index_data].quantity, 10);
            this.CartDish[parent_index].dish[index].data[index_data].total = parseFloat(this.CartDish[parent_index].dish[index].data[index_data].total).toFixed(2);
          }
        } else {
          let index_data;
          index_data = this.CheckDataSetExist(this.CartDish[parent_index].dish[index].data, data);
          if (index_data === -1) {
            this.CartDish[parent_index].dish[index].data.push(data);
          } else {
            this.CartDish[parent_index].dish[index].data[index_data].notes = this.dish.notes;
            this.CartDish[parent_index].dish[index].data[index_data].quantity = parseInt(this.CartDish[parent_index].dish[index].data[index_data].quantity, 10) + 1;
            this.CartDish[parent_index].dish[index].data[index_data].total = parseFloat(this.CartDish[parent_index].dish[index].data[index_data].totalprice) * parseInt(this.CartDish[parent_index].dish[index].data[index_data].quantity, 10);
            this.CartDish[parent_index].dish[index].data[index_data].total = parseFloat(this.CartDish[parent_index].dish[index].data[index_data].total).toFixed(2);
          }
        }
      }


    
    if (this.CartDish.length > 0 ) {
      this.comservice.set('cartDetails', this.CartDish);
      this.dataSharingService.isCartAdded.next(true);

      this.cartDishFullArr = this.CartDish[0].dish;
      this.cartDishArr = [];
      let cart_val;
      for (cart_val of this.CartDish) {
        let dish_val;
        for (dish_val of cart_val.dish) {
          this.cartDishArr.push(dish_val.id);
        }
      }
      
    }else{
      this.cartDishArr = [];
    }
  }

  CheckBusinessAddtoCart(d: any) {
    let b;
    b = this.CartDish;
    if (b === undefined) {
      return -1;
    }
    for (let c = 0; c < b.length; c++) {
      if (b[c].id === d) {
        return c;
      }
    }
    return -1;
  }
  CheckAddtoCart(d: any, index: any) {
    let b;
   
   b = this.CartDish[index].dish;
    if (b === undefined) {
      return -1;
    }
    for (let c = 0; c < b.length; c++) {
      if (b[c].id === d) {
        return c;
      }
    }
    return -1;
  }

  CheckData(cartdata: any, data: any) {
    if (data.sets.length === 0) {
      for (let i = 0; i < cartdata.length; i++) {
        if (cartdata[i].sets.length === 0) {
          return i;
        }
      }
      return -1;
    }else{
      return -1;
    }
  }
  CheckDataSetExist(cartdata: any, data: any) {
    for (let i = 0; i < cartdata.length; i++) {
      if (cartdata[i].relation) {
        let arrayDiffoptions;
        arrayDiffoptions = this.arrayDiff(cartdata[i].relation.options, data.relation.options);
        let arrayDiffchoice;
        arrayDiffchoice = this.arrayDiff(cartdata[i].relation.choices, data.relation.choices);
        if (arrayDiffoptions.arr1.length === 0 && arrayDiffoptions.arr2.length === 0 && arrayDiffchoice.arr1.length === 0 && arrayDiffchoice.arr2.length === 0) {
          return i;
        }
      }
    }
    return -1;
  }

  arrayDiff(arr1: any, arr2: any) {
    let diff;
    diff = {
      'arr1': '',
      'arr2': '',
      'concat': ''
    };

    diff.arr1 = arr1.filter(function (value: any) {
      if (arr2.indexOf(value) === -1) {
        return value;
      }
    });

    diff.arr2 = arr2.filter(function (value: any) {
      if (arr1.indexOf(value) === -1) {
        return value;
      }
    });

    diff.concat = diff.arr1.concat(diff.arr2);

    return diff;
  }

  calCartTotal() {
    this.CartSubTotal = 0.00;
    for (let i = 0; i < this.CartDish.length; i++) {
      for (let j = 0; j < this.CartDish[i].dish.length; j++) {
        for (let k = 0; k < this.CartDish[i].dish[j].data.length; k++) {
          if (this.CartDish[i].dish[j].data[k].total) {
            this.CartSubTotal = parseFloat(this.CartSubTotal) + parseFloat(this.CartDish[i].dish[j].data[k].total);
            this.CartSubTotal = parseFloat(this.CartSubTotal).toFixed(2);
          }
        }
      }
    }
    this.PendingMinimumFee = this.businessDetails.minimumfee;
    if (this.CartDish.length > 0) {
      if (this.CartDish[0].id === this.businessDetails.id) {
        if (this.PendingMinimumFee > 0) {
          if (this.CartDish.length === 0) {
            this.PendingMinimumFee = parseFloat(this.PendingMinimumFee) - parseFloat(this.CartSubTotal);
            this.PendingMinimumFee = parseFloat(this.PendingMinimumFee).toFixed(2);
          } else {
            if (this.CartDish[0].id === this.businessDetails.id) {
              this.PendingMinimumFee = parseFloat(this.PendingMinimumFee) - parseFloat(this.CartSubTotal);
              this.PendingMinimumFee = parseFloat(this.PendingMinimumFee).toFixed(2);
            }
          }
        }

        if (this.businessDetails.freedeliverystatus === '1') {
          if (parseFloat(this.CartSubTotal) >= parseFloat(this.businessDetails.freedeliveryvalue)) {
            this.PendingFreeDelivery = true;
            this.PendingFreeDeliveryValue = 0.00;
          } else {
            this.PendingFreeDelivery = false;
            this.PendingFreeDeliveryValue = parseFloat(this.businessDetails.freedeliveryvalue) - parseFloat(this.CartSubTotal);
            this.PendingFreeDeliveryValue = parseFloat(this.PendingFreeDeliveryValue).toFixed(2);
          }
        }
      }
    }

    
  }

 
  

}
