import { Component, OnInit, ViewContainerRef, ViewChild, HostListener , NgZone, ElementRef} from '@angular/core';
import { CommonService } from '../common.service';
import { DataSharingService } from '../data-share.service';
import { Router } from '@angular/router';
import { Globals } from '../globals';
import { ApiService } from '../api.service';
import { HttpClient } from '@angular/common/http';

import { MapsAPILoader } from '@agm/core';

import { ChangePasswordComponent } from '../change-password/change-password.component';
import { OrderHistoryComponent } from '../order-history/order-history.component';

// import {} from 'googlemaps'
/// <reference types="@types/googlemaps" />
@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {
  public globals: Globals;
  public language: any = null;
  public tabChange: any;
  public userdetails: any;
  public userId: any;
  public searchflag: any;
  google: any;
  
  selected_tab: any = 1;
  public uploadResponse: any;

  @ViewChild('container', { read: ViewContainerRef, static: true }) container!: ViewContainerRef;
  @ViewChild('search') public searchElement!: ElementRef;

  constructor(
    private http: HttpClient,
    private comservice: CommonService,
    private router: Router,
    private dataSharingService: DataSharingService,
    public apiservice: ApiService,
    globals: Globals,
    public viewContainerRef: ViewContainerRef,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
  ) { 
    this.globals = globals;
    this.language = this.globals.LANGUAGE[this.globals?.LANG_ID as keyof typeof this.globals.LANGUAGE];
  }

  changeTab(id:any){
    this.selected_tab = id;

    this.container.clear();
    if(id == 2){
      this.container.createComponent(ChangePasswordComponent)
    }else if(id == 3){
      this.container.createComponent(OrderHistoryComponent)
    }else if(id == 1){
     
      setTimeout(() => {
        this.googelLoad()
      }, 2000);
    }

  }


  onCloseAlert(){
    this.custom_alert_show = false;
    this.custom_alert_type = null;
    this.custom_alert_text = [];
  }

  onShowAlert(type: any,msg: any){
      this.custom_alert_type = type;
      this.custom_alert_text = msg;
      this.custom_alert_show = true;
  }

  custom_alert_header: any = "Profile";
  custom_alert_btnText: any = "Accept"; 
  custom_alert_text: any = []; 
  custom_alert_type: any = "error"; 
  custom_alert_show: any = false; 
  pressCloseOnOutsideClick: any = true;
  alertStyles:any = "";
  headerStyles:any = "";
  textStyles:any = "";
  buttonStyles:any = "";

  ngOnInit(): void {
    setTimeout(() => {
      this.language = this.globals.LANGUAGE[this.globals?.LANG_ID as keyof typeof this.globals.LANGUAGE];  
      console.log(this.language);
    }, 2000);

    this.userId = this.comservice.getFromLocal('userId');
    if (this.userId > 0) {
      this.userdetails = this.comservice.getFromLocal('user-details');
    } else {
      this.router.navigate(['/']);
    }
   
    this.apiservice.getUser(this.userId).subscribe((response: any) => {
      this.userdetails = response;
      if (this.userdetails.address === null || this.userdetails.address === 'null' || this.userdetails.address === undefined) {
        this.searchflag = 0;
      } else {
        this.searchflag = 1;
      }

      this.comservice.saveInLocal('user-details', this.userdetails);
      this.googelLoad()
    });
   
  }

  googelLoad() {

    // set current position
    this.mapsAPILoader.load().then(
      () => {
        // types: [], componentRestrictions: { country: 'IN' }
        let autocomplete: any;
        autocomplete = new google.maps.places.Autocomplete(this.searchElement.nativeElement, {});

        let place: any;

        autocomplete.addListener('place_changed', () => {
          this.ngZone.run(() => {
            let place_v: google.maps.places.PlaceResult = autocomplete.getPlace();
            place = place_v
            if (place.geometry === undefined || place.geometry === null) {
              return;
            } else {
              if (this.userdetails.location == null) {
                this.userdetails.location = {
                  zip: '',
                  address: '',
                  lat: '',
                  lng:''
                }
              }
              for (let i = 0; i < place.address_components.length; i++) {
                for (let j = 0; j < place.address_components[i].types.length; j++) {
                  if (place.address_components[i].types[j] === 'postal_code') {
                    this.userdetails.location.zip = place.address_components[i].long_name;
                  }
                }
              }
              this.userdetails.location.address = place.formatted_address;
              this.userdetails.address = place.formatted_address;
              this.userdetails.location.lat = place.geometry.location.lat();
              this.userdetails.location.lng = place.geometry.location.lng();
              this.searchflag = 1;
            }
          });
        });
      }
    );
  }

  PlaceChanged() {
    this.searchflag = 0;
  }

  updateUser() {
    let flg = true;
    const all_error_msg: any = [];
    if (this.userdetails.name == '') {
     
      all_error_msg.push(this.language.ENTER_FIRST_NAME);

      if(flg === true){
          flg = false;
      }

    }
    if (this.userdetails.last_name == '') {
     
      all_error_msg.push(this.language.ENTER_YOUR_LAST_NAME);

      if(flg === true){
          flg = false;
      }
    }
    if (this.userdetails.address == '' || this.searchflag !== 1) {
     
      all_error_msg.push(this.language.ENTER_YOUR_ADDRESS);

      if(flg === true){
          flg = false;
      }
    }
    if (this.userdetails.email == '') {
       all_error_msg.push(this.language.ENTER_EMAIL_ADDRESS);

      if(flg === true){
          flg = false;
      }
    }
    let atpos;
    atpos = this.userdetails.email.indexOf('@');
    let dotpos;
    dotpos = this.userdetails.email.lastIndexOf('.');
    if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= this.userdetails.email.length) {
     
      all_error_msg.push(this.language.ENTER_VALID_EMAIL_ADDRESS);

      if(flg === true){
          flg = false;
      }
    }
    if (this.userdetails.cel == '') {
     
      all_error_msg.push(this.language.ENTER_YOUR_MOBILE);

      if(flg === true){
          flg = false;
      }
    }
    if(flg == true){
      this.apiservice.getUpdateUser(this.userdetails).subscribe((response: any) => {
        this.userdetails = response;
        this.comservice.saveInLocal('user-details', this.userdetails);
       
        all_error_msg.push(this.language.SUCCESSFULLY_UPDATED);
        this.onShowAlert('success',all_error_msg); 
        setTimeout(()=>{                           
          this.onCloseAlert(); 
  
         } , 4000);
  
      });
    }else{
      this.onShowAlert('error',all_error_msg); 
    }
    
  }

  onSelectFile(event: any) { // called each time file input changes
    if (event.target.files && event.target.files[0]) {
      const formData = new FormData();
      formData.append('myFile', event.target.files[0], event.target.files[0].name);
      formData.append('myId', this.userdetails.id);
      this.apiservice.updateUserImage(formData).subscribe((response: any) => {
        this.uploadResponse = response;
        this.userdetails.img = this.uploadResponse.data.secure_url;
        this.comservice.saveInLocal('user-details', this.userdetails);
        
        const all_error_msg: any = [];
        all_error_msg.push("Profile Image Successfully Updated");
         this.onShowAlert('success',all_error_msg); 
         setTimeout(()=>{                           
          this.onCloseAlert(); 
  
         } , 4000);
        
      });
    }
  }

  

  goToLogout() {
    this.comservice.deleteService('Search');
    this.comservice.deleteService('businessID');
    this.comservice.deleteService('businessDetails');
    this.comservice.deleteService('cartDetails');
    this.comservice.deleteService('categoryDetails');
    this.comservice.deleteService('myClonedcategoryDetails');
    this.comservice.deleteService('MenuDetails');
    this.comservice.deleteService('orderType');
    this.comservice.deleteService('deliveryDetails');
    this.comservice.removeFromLocal('businessID');
    this.comservice.removeFromLocal('user-details');
    this.comservice.removeFromLocal('userId');
    // this.authService.signOut();
    this.userdetails = {};
    this.router.navigate(['/']);
  }

  numbertype(event:any){
    if (!/[0-9]/.test(event.key)) {event.preventDefault();}
  }


  omit_special_char(event: any) {
    let k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return(k !== 39 && k !== 34 && k !== 92 && k !== 13);
  }

  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
    e.preventDefault();
  }

}
