
 <!-- BEGIN: Page Banner Section -->
 <section class="pageBannerSection">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="pageBannerContent text-center">
                    <h2>Payment Successful</h2>
                    <div class="pageBannerPath">
                        <a routerLink="/">Home</a>&nbsp;&nbsp; / &nbsp;&nbsp;<span>Cart</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- END: Page Banner Section -->

<!-- ayment Success Start -->
<section class="success-page">
    <div class="container">
        <div class="success-img">
            <img src="../../assets/images/success-banner.svg" alt="success">
        </div>

        <h2>Payment Successful !</h2>
        <h4>Thank You For Your Purchase</h4>
        <p>You will receive an order confirmation email details of your order.</p>
        <p>Youe Order #{{orderId}}</p>


        <div class="row justify-content-md-center mt-50">
        <div class="col-lg-8 col-md-12 newbox">
               <h3>Your order</h3>
               <div class="your_order_table">
                    <div class="order_row">
                        <div class="">Product</div>
                        <div class="">Total</div>
                    </div>

                    <div *ngFor="let cartdish of CartDish;let cartindex = index">
                    <div *ngFor="let cdish of cartdish['dish'];let dishindex = index">
                
                        <div class="order_row" *ngFor="let dish of cdish['data'];let dataindex = index">
                            <div class="">{{dish.name}} <strong> × {{dish.quantity}}</strong></div>
                            <div class=""><span class="amount"><sp *ngIf="businessDetails.currency_symbol">{{businessDetails.currency_symbol}}</sp>{{dish.total}}</span></div>
                        </div>
                    </div>
                    </div>  
                
                    <div class="cart_subtotal">
                    <div><span *ngIf="language != null">{{language.ORDER_TEMPLATE_SUB_TOTAL}}</span></div>
                    <div><span class="amount">{{businessDetails.currency_symbol}}{{SubTotal}}</span></div>
                    </div>
                    
                    <div class="cart_subtotal" *ngIf="cartFee[0].taxprice > 0">
                            <div><span *ngIf="language != null">{{language.ORDER_TEMPLATE_TAX}}({{cartFee[0].tax}}%)</span></div>
                            <div><span class="amount">{{businessDetails.currency_symbol}}{{cartFee[0].taxprice}}</span></div>
                    </div>

                    <div class="cart_subtotal" *ngIf="cartFee[0].servicefeeprice > 0">
                    <div><span *ngIf="language != null">{{language.SERVICE_FEE}} ({{cartFee[0].servicefee}}%)</span></div>
                    <div><span class="amount">{{businessDetails.currency_symbol}}{{cartFee[0].servicefeeprice}}</span></div>
                    </div>
                    
                    <div class="cart_subtotal">
                        <div>Order Total</div>
                        <div><span class="amount">{{businessDetails.currency_symbol}}{{cartFee[0].total}}</span></div>
                    </div>      
             
                  
             </div>

         </div>
         </div>








    </div>
    
</section>
<!-- Payment Success End -->


    <!-- feature-area-start -->
    <app-feature-area></app-feature-area>
    <!-- feature-area-end -->

