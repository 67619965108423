import { WINDOW } from '@ng-toolkit/universal';
import { Component, Inject, OnInit, ChangeDetectorRef, ViewRef} from '@angular/core';
import { CanonicalService } from './canonical.service';
import { CommonService } from './common.service';
import { DataSharingService } from './data-share.service';
import { Router , Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { Location } from '@angular/common';
import { Globals } from './globals';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import * as $ from 'jquery' 

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'basic_ecommerce';
  public globals: Globals;
  public appType: any;
  public langId: any;
  public language: any;
  public langlist: any;
  public langFlag = false;
  interval: any;
  visible = true;
  public stickyheight: any;
  isUserLoggedIn: boolean = false;
  public userdetails: any;
  public userId: any;
  public headerType = false;
  public CartDish: any;
  public cartQuantity = 0;

  public languageResponse:any;

  public businessDetails: any;
  public businessId: any;

  routeUrl: any;

  productCategory: any = []; 
  public selected_category = 0;
  public header_search: any = '';

  current_year: any;

  constructor(
    private http: HttpClient,
    @Inject(WINDOW) private window: Window,
    private canonicalService: CanonicalService,
    private comservice: CommonService,
    location: Location,
    private router: Router,
    private dataSharingService: DataSharingService,
    private cdRef: ChangeDetectorRef,
    public apiservice: ApiService,
    globals: Globals,
    private titleService: Title,
    private metaService: Meta,
    private route: ActivatedRoute
  ) {

    this.current_year = new Date().getFullYear();

    this.globals = globals;
    this.appType = this.globals.APP_TYPE;
    const parameters = { f: 'fetchlang' };
    this.http.post(this.globals.API_ENDPOINT_LANGUAGE, JSON.stringify(parameters)).subscribe(res => {
      this.langFlag = true;
      this.languageResponse = res;
      this.globals.LANGUAGE = this.languageResponse.data;
      this.globals.LANG_ID = this.languageResponse.langId;
      this.globals.LANG_LIST = this.languageResponse.langlist;
      this.langlist = this.globals.LANG_LIST;
      if (this.comservice.getFromLocal('langId') === undefined || this.comservice.getFromLocal('langId') === null) {
        this.language = this.globals.LANGUAGE[this.globals.LANG_ID];
        this.langId = this.globals.LANG_ID;
      } else {
        let lang;
        lang = this.comservice.getFromLocal('langId');
        this.globals.LANG_ID = lang;
        this.language = this.globals.LANGUAGE[this.globals.LANG_ID];
      }
      this.canonicalService.setCanonicalURL();
    });

    this.dataSharingService.isUserLoggedIn.subscribe(value => {
      this.isUserLoggedIn = value;
      this.fetchData();
    });
       
    this.dataSharingService.isCartAdded.subscribe(value => {
      if (value === true) {
        this.CartDish = this.comservice.get('cartDetails');
        this.cartQuantity = 0;
        for (let cart of this.CartDish) {
          for (let dish of cart.dish) {
            this.cartQuantity = this.cartQuantity + dish.quantity;
          }
        }
      }
    });

    setTimeout(() => {
      this.language = this.globals.LANGUAGE[this.globals?.LANG_ID as keyof typeof this.globals.LANGUAGE];  
    }, 2000);


    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
          // Show progress spinner or progress bar
          console.log('Route change detected');
          
      }

      if (event instanceof NavigationEnd) {
          // Hide progress spinner or progress bar
          //this.currentRoute = event.url;          
          console.log(event.url);

         if(event.url.search('products') == -1){
            this.selected_category = 0;
            this.header_search = '';
         }
          
      }

      if (event instanceof NavigationError) {
          // Hide progress spinner or progress bar

          // Present error to user
          console.log(event.error);
      }

  });

    
  }


  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {

        if(params['cat']){
          this.selected_category = params['cat'];
        }else{
          this.selected_category = 0;
        }
        if(params['keyword']){
          this.header_search = params['keyword'];
        }else{
          this.header_search = '';
        }
      }
    );

    this.titleService.setTitle('PineKart');
    this.metaService.updateTag({name: 'keywords', content: 'PineKart, Order Online'});
    this.metaService.updateTag({name: 'description', content: 'PineKart Ordering Web'});
    this.metaService.updateTag({name: 'robots', content: 'index, follow'});

    this.metaService.updateTag({property: 'og:url', content: this.globals.SITE_URL});

    this.userId = this.comservice.getFromLocal('userId');
    if (this.userId > 0) {
      this.isUserLoggedIn = true;
      this.userdetails = this.comservice.getFromLocal('user-details');
    }
    this.CartDish = this.comservice.get('cartDetails');
    if (this.CartDish !== '') {
      this.cartQuantity = 0;
      for (let cart of this.CartDish) {
        for (let dish of cart.dish) {
          this.cartQuantity = this.cartQuantity + dish.quantity;
        }
      }
    }

    var window : Window;

    $(function(){
      setTimeout(function(){
        $('.mainMenu ul li.menu-item-has-children > a').on('click', function(e){
          e.preventDefault();
          if(window.innerWidth < 1024){
              $(this).siblings('ul, .megaMenu').slideToggle();
          }
        });
        $('.menuToggler').on('click', function(e){
            e.preventDefault();
            $('.mainMenu').slideToggle();
            $(this).toggleClass('active');
        });
        }, 3000);   
    });

    this.apiservice.getProductCategory(this.globals.BUSINESS_ID).subscribe((response: any) => {
        this.productCategory = response;
    });

    this.userdetails = this.comservice.getFromLocal('user-details');
      let uid = -1;
      if (this.userdetails) {
        if (this.userdetails.id) {
          uid = this.userdetails.id;
        }
      }
      this.apiservice.getBusiness(this.globals.BUSINESS_SLUG, uid).subscribe((response: any) => {
            if (Object.keys(response).length === 0) {
              this.router.navigate(['/404']);
            } else {
              this.comservice.setService('businessID', response.id);
              this.businessId  = response.id;

              let is_img;
              is_img = JSON.parse(response.is_img);
              if (is_img.is_img === 1) {
                response.logo = is_img.data.secure_url;
              } else {
                response.logo = './assets/images/business_logo.png';
              }
              let is_banner;
              is_banner = JSON.parse(response.is_banner);
              if (is_banner.is_img === 1) {
                response.banner = is_banner.data.secure_url;
              } else {
                response.banner = './assets/images/business_banner.png';
              }
              this.comservice.set('businessDetails', response);
              this.businessDetails = response;

              console.log(this.businessDetails);
        }
      });

   

  }

  fetchData() {
    this.userdetails = this.comservice.getFromLocal('user-details');
  }

  header_category_on_change(){
    if(this.selected_category > 0){
    var h = document.getElementsByClassName('header_category');
      this.removeColor(h, 'red');
    }
  }


  removeColor(coll:any, color:any){

    for(var i=0, len=coll.length; i<len; i++)
    {
        //coll[i].style["background-color"] = color;

        coll[i].style["border"] = '';
    }
}

changeColor(coll:any, color:any){

    for(var i=0, len=coll.length; i<len; i++)
    {
        //coll[i].style["background-color"] = color;

        coll[i].style["border"] = '3px solid ' + color;
    }
}

  search_by_header_category(){

    if(this.selected_category == 0){
        var h = document.getElementsByClassName('header_category');
        this.changeColor(h, 'red');
    }else{

      var h = document.getElementsByClassName('header_category');
      this.removeColor(h, 'red');

      if(this.selected_category == 0 && this.header_search == ''){
        this.router.navigate(['/products']);
      }else{
        if(this.header_search != '' && this.selected_category > 0){
          this.router.navigate(
            ['/products'],
            { queryParams: { cat: this.selected_category, keyword: this.header_search } }
          );
        }else{
          if(this.header_search == '' && this.selected_category != 0){
            this.router.navigate(
              ['/products'],
              { queryParams: { cat: this.selected_category} }
            );
          }else if(this.header_search != '' && this.selected_category == 0){
            this.router.navigate(
              ['/products'],
              { queryParams: { keyword: this.header_search} }
            );
          }
        }
      }
    }

  }

  
  goToLogout() {
    this.comservice.clear();
    this.comservice.remove('cartDetails');
    this.comservice.deleteService('cartDetails');
    this.comservice.deleteService('businessID');
    this.comservice.deleteService('businessDetails');
    this.comservice.deleteService('categoryDetails');
    this.comservice.deleteService('myClonedcategoryDetails');
    this.comservice.deleteService('MenuDetails');
    this.comservice.removeFromLocal('businessID');
    this.comservice.removeFromLocal('user-details');
    this.comservice.removeFromLocal('userId');
    this.isUserLoggedIn = false;
    this.userdetails = {};
    this.router.navigate(['/']);
  }

  
}
