import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

import { Router, ActivatedRoute } from '@angular/router';

import { CommonService } from './../common.service';
import { ApiService } from './../api.service';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../globals';

@Component({
  selector: 'app-business-review',
  templateUrl: './business-review.component.html',
  styleUrls: ['./business-review.component.scss']
})
export class BusinessReviewComponent implements OnInit {

  testimonialcustomOptions2: OwlOptions = {
    autoplay: true,
    margin: 24,
    loop: false,
    nav:false,
    navText: ['<i class="fa-solid fa-arrow-left"></i>', '<i class="fa-solid fa-arrow-right"></i>'],
    dots: false,
    items: 3,
    responsive: {
        0: {
            items: 1,
            nav: false
        },
        576: {
            items: 1
        },
        768: {
            items: 2
        },
        992: {
            items: 3
        },
        1200: {
            items: 3
        }
    }
   
  }

  review_list: any = [];

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private comservice: CommonService,
    private apiservice: ApiService,
    private router: Router,
    private globals: Globals
  ) { }

  ngOnInit(): void {
    this.apiservice.getReviewByBusiness(this.globals.BUSINESS_ID,0).subscribe((response: any) => {
        this.review_list = response.data;
    });
  }

}
