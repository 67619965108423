import { Injectable } from '@angular/core';

//var API_ENDPOINT = 'https://menuhuts.com/';

var API_ENDPOINT = 'https://demo.menuhuts.com/';

@Injectable()
export class Globals {
  public static API_ENDPOINT_LOGIN_CHECK = API_ENDPOINT + 'ap-admin/lib/login/login_front.php';
  public static API_ENDPOINT_LANGUAGE = API_ENDPOINT + 'apiv/module/lang/lang';
  public static API_ENDPOINT_PRODUCT = API_ENDPOINT + 'apiv/module/product/product';
  public static API_ENDPOINT_BUSINESS = API_ENDPOINT + 'apiv/module/business/business';
  public static API_ENDPOINT_USER = API_ENDPOINT + 'apiv/module/user/user';
  public static API_ENDPOINT_UPLOAD = API_ENDPOINT + 'apiv/module/user/upload.php';
  public static API_ENDPOINT_ORDER = API_ENDPOINT + 'apiv/module/order/order';
  public static API_ENDPOINT_PAYMENT = API_ENDPOINT + 'apiv/module/payment/payment';
  public static API_ENDPOINT_PLACE = API_ENDPOINT + 'apiv/module/place/place';
  public static API_ENDPOINT_COMMON = API_ENDPOINT + 'apiv/module/common/common';


  public static API_ENDPOINT_STRIPE = API_ENDPOINT + 'apiv/module/stripe/create-checkout-session.php';
  public static API_ENDPOINT_STRIPE_SUCCESS = API_ENDPOINT + 'payment';
  public static API_ENDPOINT_STRIPE_ERROR = API_ENDPOINT + 'checkout';
  

  public SITE_URL = API_ENDPOINT;
  public API_ENDPOINT_LANGUAGE = API_ENDPOINT + 'apiv/module/lang/rest-lang';
  public BUSINESS_SLUG = 'pinekart';
  public BUSINESS_ID = 460;
  //public BUSINESS_SLUG = 'menuhuts';
  //public BUSINESS_ID = 33;
  public APP_TYPE = false;
  public ADDRESS_RESTRICTION = true;
  public LANGUAGE:any = {};
  public LANG_ID: any = 1;
  public LANG_LIST:any = {};
}
