import { Component, OnInit } from '@angular/core';
import { CommonService } from '../common.service';
import { DataSharingService } from '../data-share.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Globals } from '../globals';
import { ApiService } from '../api.service';
import { HttpClient } from '@angular/common/http';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent implements OnInit {

  public globals: Globals;
  public language: any = null;
  public businessDetails: any = null;

  public productDetails: any = [];

  options = { autoHide: true, scrollbarMinSize: 100 };
  public businessId: any;
  public categoryDetails: any;
  public MenuDetails: any = [];
  public userdetails: any;
  public cartDetails: any;
  public orderType: any;
  public CartDish: any = [];
  public CartSubTotal: any;
  public PendingMinimumFee: any;
  public PendingFreeDelivery = false;
  public PendingFreeDeliveryValue: any;
  public myClonedcategoryDetails: any;
  public myClonedcategory: any;
  public from_order: any;
  public indexcart: any;
  public preorderDetails: any = {
    preorder: false,
    preorderDate: '',
    preorderTime: '',
    preorderMenu: 0
  };
  public menuDisplay = true;
  public productDisplay = false;
  public suggestProduct: any = [];
  public suggestProductArray: any = [];
  public catLength: Number = 0;
  public catLimit: Number = 0;
  public scroll = 0;
  public appType: any;
  public cartQuantity = 0;
  public stickyheight: any;

  public selectable = false;
  public removable = true;
  public dish: any;
  public dishTotal: any;
  public from_order_pos: any;

  public itemQty: any = 1; 


  constructor(
    private http: HttpClient,
    private comservice: CommonService,
    private router: Router,
    private dataSharingService: DataSharingService,
    public apiservice: ApiService,
    globals: Globals,
    private route: ActivatedRoute
  ) { 
    this.globals = globals;
    this.language = this.globals.LANGUAGE[this.globals?.LANG_ID as keyof typeof this.globals.LANGUAGE];

    let cart = this.comservice.get('cartDetails');
      if(cart.length > 0){
        this.CartDish = cart;
      }

  }

  customOptions: OwlOptions = {
    autoplay: false,
    margin: 24,
    //loop: true,
    nav: true,
    navText: ['<i class="fa-solid fa-arrow-left"></i>', '<i class="fa-solid fa-arrow-right"></i>'],
    dots: false,
    items: 3,
    responsive: {
      0: {
        items: 3
      },
              
    },
   
  }

  selected_image: any;

  selectimage(img_url:any){

    this.selected_image = img_url;

  }

  ngOnInit(): void {
    setTimeout(() => {
      this.language = this.globals.LANGUAGE[this.globals?.LANG_ID as keyof typeof this.globals.LANGUAGE];  
    }, 1500);

    /*this.spinner.show();
    setTimeout(() => {
        this.spinner.hide();
    }, 10000);*/
        let id = this.route.snapshot.paramMap.get('id');
    
        this.businessDetails = this.comservice.getService('businessDetails');

        this.apiservice.getproductbyId(id).subscribe((responseMenu: any) => {

          this.productDetails = responseMenu[0];
          this.selected_image = this.productDetails.img;

          if(this.CartDish.length > 0){
            this.CartDish.forEach((productElement:any, productIndex: any) => {
              productElement.dish.forEach((menuElement:any) => {

                if(menuElement.id == this.productDetails.id){
                  this.itemQty = menuElement.quantity;

                }

              });
            });
          }

        });

      let uid = -1;
      
      this.apiservice.getBusiness(this.globals.BUSINESS_SLUG, uid).subscribe((response: any) => {
            if (Object.keys(response).length === 0) {
              this.router.navigate(['/404']);
            } else {
              this.comservice.setService('businessID', response.id);

              let is_img;
              is_img = JSON.parse(response.is_img);
              if (is_img.is_img === 1) {
                response.logo = is_img.data.secure_url;
              } else {
                response.logo = './assets/images/business_logo.png';
              }
              let is_banner;
              is_banner = JSON.parse(response.is_banner);
              if (is_banner.is_img === 1) {
                response.banner = is_banner.data.secure_url;
              } else {
                response.banner = './assets/images/business_banner.png';
              }
              this.comservice.setService('businessDetails', response);
              this.businessDetails = response;
        }
      });

  }

  decrement(itemQty: any){
    if(this.itemQty > 1){
      this.itemQty = (this.itemQty - 1);
    }
  }

  increment(itemQty: any){
    this.itemQty = (this.itemQty + 1);
  }

  CheckDataDecrement(cartdata: any){
    if(cartdata.length > 0){
      for (let i = 0; i < cartdata.length; i++) {
        if (cartdata[i].sets.length === 0) {
          return i;
        }
      }
      return -1;
    }else{
      return -1
    }

  }

  
  addTocartDecrement(catid:any, dish: any) {

    if(this.itemQty > 1){
      this.itemQty = (this.itemQty - 1);
    

    this.dish = dish;       

    let index = this.CheckBusinessAddtoCart(this.businessDetails.id);      
   
    let dishindex;
    dishindex = this.CheckAddtoCart(this.dish.id, index);
    let dishdataindex = this.CheckDataDecrement(this.CartDish[index].dish[dishindex].data);
    
    this.CartDish[index].dish[dishindex].quantity = this.CartDish[index].dish[dishindex].quantity - 1;
    this.CartDish[index].dish[dishindex].data[dishdataindex].quantity = this.CartDish[index].dish[dishindex].data[dishdataindex].quantity - 1;
    this.CartDish[index].dish[dishindex].data[dishdataindex].total = parseFloat(this.CartDish[index].dish[dishindex].data[dishdataindex].totalprice) * parseInt(this.CartDish[index].dish[dishindex].data[dishdataindex].quantity, 10);
    this.CartDish[index].dish[dishindex].data[dishdataindex].total = parseFloat(this.CartDish[index].dish[dishindex].data[dishdataindex].total).toFixed(2);
    if (this.CartDish[index].dish[dishindex].data[dishdataindex].quantity == 0) {
      this.CartDish[index].dish[dishindex].data.splice(dishdataindex, 1);
      if (this.CartDish[index].dish[dishindex].data.length == 0) {
        this.CartDish[index].dish.splice(dishindex, 1);
      }
      if (this.CartDish[index].dish.length == 0) {
        this.CartDish.splice(index, 1);
      }
      this.comservice.set('cartDetails', this.CartDish);
      this.dataSharingService.isCartAdded.next(true);
    } else {
      this.comservice.set('cartDetails', this.CartDish);
      this.dataSharingService.isCartAdded.next(true);
    }

  }  
  
}


  openProduct(catid:any, dish: any) {


    this.addToCart(dish, this.itemQty);
   
  }
  addToCart(dishTemp: any, qty: any) {
    this.dish = dishTemp;
    this.dishTotal = this.dish.price;

    let bDeatilsCloned;
    bDeatilsCloned = JSON.stringify(this.businessDetails);
    let bDeatils;
    bDeatils = JSON.parse(bDeatilsCloned);
    bDeatils.street = JSON.parse(bDeatils.street);
    bDeatils.type = JSON.parse(bDeatils.type);
    bDeatils.location = JSON.parse(bDeatils.location);
    bDeatils.is_img = JSON.parse(bDeatils.is_img);
    bDeatils.is_banner = JSON.parse(bDeatils.is_banner);
    bDeatils.cuisine = JSON.parse(bDeatils.cuisine);

    let business;
    business = {
      id: this.businessDetails.id,
      details: bDeatils,
      dish: new Array()
    };


    let activeIngredients;
    activeIngredients = new Array();

    let parent_index = this.CheckBusinessAddtoCart(this.businessDetails.id);
    if (parent_index === -1) {
      this.CartDish.push(business);
    }


    let parent_index_test = this.CheckBusinessAddtoCart(this.businessDetails.id);
    let index_test;
    index_test = this.CheckAddtoCart(dishTemp.id, parent_index_test);

    if (index_test === -1) {
      qty = this.itemQty;

    } else {
     
      this.itemQty = (this.itemQty + 1);
      qty = this.itemQty;
    }

    let dish;
    dish = {
      'id': this.dish.id,
      'category': this.dish.category,
      'quantity': qty,
      'data' : new Array()
    };


    let data;
    data = {
      'id': this.dish.id,
      'name': this.dish.name,
      'price': this.dish.price,
      'delprice': this.dish.delprice,
      'pickprice': this.dish.pickprice,
      'notes': this.dish.notes,
      'totalprice': this.dishTotal,
      'total': this.dishTotal,
      'is_img': this.dish.is_img,
      'img': this.dish.img,
      'spicy': this.dish.spicy,
      'spicyquantity': this.dish.spicyquantity,
      'veg': this.dish.veg,
      'nonveg': this.dish.nonveg,
      'ingredients': this.dish.ingredients,
      'ingredientsarray': this.dish.ingredientsarray,
      'activeIngredients': activeIngredients,
      'quantity': qty,
      'sets': new Array(),
      'relation': {
        'options': new Array(),
        'choices': new Array()
      },
      'free': {}
    };


    
    parent_index = this.CheckBusinessAddtoCart(this.businessDetails.id);
    let index;
    index = this.CheckAddtoCart(this.dish.id, parent_index);

     
      if (index === -1) {
        dish.data.push(data);
        this.CartDish[parent_index].dish.push(dish);
      } else {
            
        this.CartDish[parent_index].dish[index].quantity = parseInt(qty);
        if (data.sets.length === 0) {
          let index_data;
          index_data = this.CheckData(this.CartDish[parent_index].dish[index].data, data);
          if (index_data === -1) {
            this.CartDish[parent_index].dish[index].data.push(data);
          } else {
            this.CartDish[parent_index].dish[index].data[index_data].notes = this.dish.notes;
            this.CartDish[parent_index].dish[index].data[index_data].quantity = parseInt(qty);
            this.CartDish[parent_index].dish[index].data[index_data].total = parseFloat(this.CartDish[parent_index].dish[index].data[index_data].totalprice) * parseInt(this.CartDish[parent_index].dish[index].data[index_data].quantity, 10);
            this.CartDish[parent_index].dish[index].data[index_data].total = parseFloat(this.CartDish[parent_index].dish[index].data[index_data].total).toFixed(2);
          }
        } else {
          let index_data;
          index_data = this.CheckDataSetExist(this.CartDish[parent_index].dish[index].data, data);
          if (index_data === -1) {
            this.CartDish[parent_index].dish[index].data.push(data);
          } else {
            this.CartDish[parent_index].dish[index].data[index_data].notes = this.dish.notes;
            this.CartDish[parent_index].dish[index].data[index_data].quantity = parseInt(qty);
            this.CartDish[parent_index].dish[index].data[index_data].total = parseFloat(this.CartDish[parent_index].dish[index].data[index_data].totalprice) * parseInt(this.CartDish[parent_index].dish[index].data[index_data].quantity, 10);
            this.CartDish[parent_index].dish[index].data[index_data].total = parseFloat(this.CartDish[parent_index].dish[index].data[index_data].total).toFixed(2);
          }
        }
      }


    
    if (this.CartDish.length > 0) {
      this.comservice.set('cartDetails', this.CartDish);
      this.dataSharingService.isCartAdded.next(true);
    }
  }

  CheckBusinessAddtoCart(d: any) {
    let b;
    b = this.CartDish;
    if (b === undefined) {
      return -1;
    }
    for (let c = 0; c < b.length; c++) {
      if (b[c].id === d) {
        return c;
      }
    }
    return -1;
  }
  CheckAddtoCart(d: any, index: any) {
    let b;
    b = this.CartDish[index].dish;
    if (b === undefined) {
      return -1;
    }
    for (let c = 0; c < b.length; c++) {
      if (b[c].id === d) {
        return c;
      }
    }
    return -1;
  }

  CheckData(cartdata: any, data: any) {
    if (data.sets.length === 0) {
      for (let i = 0; i < cartdata.length; i++) {
        if (cartdata[i].sets.length === 0) {
          return i;
        }
      }
      return -1;
    }else{
      return -1;
    }
  }
  CheckDataSetExist(cartdata: any, data: any) {
    for (let i = 0; i < cartdata.length; i++) {
      if (cartdata[i].relation) {
        let arrayDiffoptions;
        arrayDiffoptions = this.arrayDiff(cartdata[i].relation.options, data.relation.options);
        let arrayDiffchoice;
        arrayDiffchoice = this.arrayDiff(cartdata[i].relation.choices, data.relation.choices);
        if (arrayDiffoptions.arr1.length === 0 && arrayDiffoptions.arr2.length === 0 && arrayDiffchoice.arr1.length === 0 && arrayDiffchoice.arr2.length === 0) {
          return i;
        }
      }
    }
    return -1;
  }

  arrayDiff(arr1: any, arr2: any) {
    let diff;
    diff = {
      'arr1': '',
      'arr2': '',
      'concat': ''
    };

    diff.arr1 = arr1.filter(function (value: any) {
      if (arr2.indexOf(value) === -1) {
        return value;
      }
    });

    diff.arr2 = arr2.filter(function (value: any) {
      if (arr1.indexOf(value) === -1) {
        return value;
      }
    });

    diff.concat = diff.arr1.concat(diff.arr2);

    return diff;
  }


}
