import { Component, OnInit } from '@angular/core';
import { CommonService } from '../common.service';
import { DataSharingService } from '../data-share.service';
import { Router } from '@angular/router';
import { Globals } from '../globals';
import { ApiService } from '../api.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit {

  public forgot: any = {email: ''};
  public globals: Globals;
  public language: any = null;

  constructor(
    private http: HttpClient,
    private comservice: CommonService,
    private router: Router,
    private dataSharingService: DataSharingService,
    public apiservice: ApiService,
    globals: Globals,
  ) { 
    this.globals = globals;
    this.language = this.globals.LANGUAGE[this.globals?.LANG_ID as keyof typeof this.globals.LANGUAGE];
  }

  
  
  onCloseAlert(){
    this.custom_alert_show = false;
    this.custom_alert_type = null;
    this.custom_alert_text = [];

}

onShowAlert(type: any,msg: any){
    this.custom_alert_type = type;
    this.custom_alert_text = msg;
    this.custom_alert_show = true;
}


  custom_alert_header: any = "Forgot Password";
  custom_alert_btnText: any = "Accept"; 
  custom_alert_text: any = []; 
  custom_alert_type: any = "error"; 
  custom_alert_show: any = false; 
  pressCloseOnOutsideClick: any = true;
  alertStyles:any = "";
  headerStyles:any = "";
  textStyles:any = "";
  buttonStyles:any = "";

  ngOnInit(): void {
    setTimeout(() => {
      this.language = this.globals.LANGUAGE[this.globals?.LANG_ID as keyof typeof this.globals.LANGUAGE];  
    }, 1500);
  }

  onForgotSubmit() {
    let flg = true;
    const all_error_msg: any = [];
    if (this.forgot.email === '') {
           
      all_error_msg.push(this.language.ENTER_YOUR_EMAIL);

      if(flg === true){
          flg = false;
      }
    }
    let atpos;
    atpos = this.forgot.email.indexOf('@');
    let dotpos;
    dotpos = this.forgot.email.lastIndexOf('.');
    if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= this.forgot.email.length) {
      
      all_error_msg.push(this.language.ENTER_VALID_EMAIL_ADDRESS);

      if(flg === true){
          flg = false;
      }
     
    }
    if(flg == true){
      this.apiservice.getForgot(this.forgot).subscribe((res: any) => {
        if (res.status) {
         
          all_error_msg.push(res.message);
          this.onShowAlert("success",all_error_msg); 
          
          this.forgot = { email: '' };
          setTimeout(()=>{                           
            this.onCloseAlert(); 
  
           } , 4000);
        } else {
          
          all_error_msg.push(res.message);
          this.onShowAlert('error',all_error_msg);
        
        }
      });
    }else{
      this.onShowAlert('error',all_error_msg); 
    }
    
  }

}
