import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { NgtUniversalModule } from '@ng-toolkit/universal';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { DatePipe } from '@angular/common';

import { Globals } from './globals';
import { CommonService } from './common.service';
import { ApiService } from './api.service';
import { DataSharingService } from './data-share.service';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMasonryModule } from 'ngx-masonry';

import {NgxPaginationModule} from 'ngx-pagination'; 

import { SimplebarAngularModule } from 'simplebar-angular';

import { FilterPipe, OrderFilterPipe, FilterMenuPipe } from './filter.pipe';

import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import {MatDialogModule} from '@angular/material/dialog';


import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AboutComponent } from './about/about.component';
import { GalleryComponent } from './gallery/gallery.component';
import { TestimonialComponent } from './testimonial/testimonial.component';
import { ContactsComponent } from './contacts/contacts.component';
import { BusinessReviewComponent } from './business-review/business-review.component';
import { FeatureAreaComponent } from './feature-area/feature-area.component';
import { SignupComponent } from './signup/signup.component';
import { LoginComponent } from './login/login.component';
import { ForgotComponent } from './forgot/forgot.component';

import {MatSnackBarModule} from '@angular/material/snack-bar';
import { CustomAlertComponent } from './custom-alert/custom-alert.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { OrderHistoryComponent } from './order-history/order-history.component';
import { RecoveryComponent } from './recovery/recovery.component';
import { ProductsComponent } from './products/products.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { ShoppingCartComponent } from './shopping-cart/shopping-cart.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { OrderdetailsComponent } from './orderdetails/orderdetails.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { RefundPolicyComponent } from './refund-policy/refund-policy.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    AboutComponent,
    GalleryComponent,
    TestimonialComponent,
    ContactsComponent,
    BusinessReviewComponent,
    FeatureAreaComponent,
    SignupComponent,
    LoginComponent,
    ForgotComponent,
    CustomAlertComponent,
    MyAccountComponent,
    ChangePasswordComponent,
    OrderHistoryComponent,
    RecoveryComponent,
    ProductsComponent,
    FilterPipe,
    OrderFilterPipe,
    FilterMenuPipe,
    ProductDetailsComponent,
    ShoppingCartComponent,
    CheckoutComponent,
    ConfirmationComponent,
    OrderdetailsComponent,
    TermsConditionsComponent,
    PrivacyPolicyComponent,
    RefundPolicyComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    CarouselModule,
    BrowserAnimationsModule,
    NgtUniversalModule,
    TransferHttpCacheModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    NgxMasonryModule,
    MatSnackBarModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAwWmYexCDWu-rTj2J2ngCje4JP0MFnYSk',
      libraries: ['places']
    }),
    AgmDirectionModule,
    NgxPaginationModule,
    NgxSkeletonLoaderModule.forRoot(),
    SimplebarAngularModule,
    MatDialogModule,
  ],
  exports: [
    MatSnackBarModule,
    MatDialogModule,
  ],
  providers: [ 
    Globals,
    CommonService,
    ApiService,
    DataSharingService,
    DatePipe
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class AppModule { }
