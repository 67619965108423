import { NgModule } from '@angular/core';
import { RouterModule, Routes, Router, PreloadAllModules} from '@angular/router';

import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { GalleryComponent } from './gallery/gallery.component';
import { TestimonialComponent } from './testimonial/testimonial.component';
import { ContactsComponent } from './contacts/contacts.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { ForgotComponent } from './forgot/forgot.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { OrderHistoryComponent } from './order-history/order-history.component';
import { RecoveryComponent } from './recovery/recovery.component';
import { ProductsComponent } from './products/products.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { ShoppingCartComponent } from './shopping-cart/shopping-cart.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { OrderdetailsComponent } from './orderdetails/orderdetails.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { RefundPolicyComponent } from './refund-policy/refund-policy.component';

const routes: Routes = [
  
  {path: '',
    component: HomeComponent,
    data: {preload: true}
  },
  {path: 'home',
    component: HomeComponent,
    data: {preload: true}
  },
  {path: 'about',
    component: AboutComponent,
    data: {preload: true}
  },
  {path: 'gallery',
    component: GalleryComponent,
    data: {preload: true}
  },
  {path: 'testimonial',
    component: TestimonialComponent,
    data: {preload: true}
  },
  {path: 'contacts',
    component: ContactsComponent,
    data: {preload: true}
  },
  {path: 'login',
    component: LoginComponent,
    data: {preload: true}
  },
  {path: 'signup',
    component: SignupComponent,
    data: {preload: true}
  },
  {path: 'forgotpassword',
    component: ForgotComponent,
    data: {preload: true}
  },
  {path: 'myaccount',
    component: MyAccountComponent,
  },
  {path: 'changepassword',
    component: ChangePasswordComponent,
  },
  {path: 'orderhistory',
    component: OrderHistoryComponent,
  },
  {path: 'recovery/:id',
    component: RecoveryComponent,
  },
  {path: 'products',
    component: ProductsComponent,
  },
  {path: 'product-details/:id',
    component: ProductDetailsComponent,
  },
  {path: 'cart',
  component: ShoppingCartComponent,
  },
  {path: 'checkout',
  component: CheckoutComponent,
  },
  {path: 'confirmation',
  component: ConfirmationComponent,
  },
  {path: 'terms-conditions',
    component: TermsConditionsComponent,
    data: {preload: true}
  },
  {path: 'privacy-policy',
    component: PrivacyPolicyComponent,
    data: {preload: true}
  },
  {path: 'refund-policy',
    component: RefundPolicyComponent,
    data: {preload: true}
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

/*@NgModule({
  imports: [RouterModule.forRoot(routes,{
    scrollPositionRestoration: 'top'
  })]*/