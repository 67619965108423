import { Component, OnInit } from '@angular/core';
import { CommonService } from '../common.service';
import { DataSharingService } from '../data-share.service';
import { Router } from '@angular/router';
import { Globals } from '../globals';
import { ApiService } from '../api.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public globals: Globals;
  public language: any = null;
  public loginform: any = {
    email: '',
    password: ''
  };

  public userdetails: any;
  userId: any = 0;

  constructor(
    private http: HttpClient,
    private comservice: CommonService,
    private router: Router,
    private dataSharingService: DataSharingService,
    public apiservice: ApiService,
    globals: Globals,
  ) { 
    this.globals = globals;
    this.language = this.globals.LANGUAGE[this.globals?.LANG_ID as keyof typeof this.globals.LANGUAGE];

    this.userdetails = this.comservice.getFromLocal('user-details');
     
    if (this.userdetails) {
      if (this.userdetails.id) {
        this.userId = this.userdetails.id;
      }
    }
  }

  proceed(){
    let cart = this.comservice.get('cartDetails');
    if(this.userId > 0){
      if(cart.length > 0){
        this.router.navigate(['/checkout']);
      }else{
        this.router.navigate(['/products']);
      }
    }else{
      if(cart.length > 0){
        this.router.navigate(['/checkout']);
      }else{
        this.router.navigate(['/products']);
      }
     
    }
  }

  
  onCloseAlert(){
    this.custom_alert_show = false;
    this.custom_alert_type = null;
    this.custom_alert_text = [];

}

onShowAlert(type: any,msg: any){
    this.custom_alert_type = type;
    this.custom_alert_text = msg;
    this.custom_alert_show = true;
}


  custom_alert_header: any = "Login";
  custom_alert_btnText: any = "Accept"; 
  custom_alert_text: any = []; 
  custom_alert_type: any = "error"; 
  custom_alert_show: any = false; 
  pressCloseOnOutsideClick: any = true;
  alertStyles:any = "";
  headerStyles:any = "";
  textStyles:any = "";
  buttonStyles:any = "";

  ngOnInit(): void {
    setTimeout(() => {
      this.language = this.globals.LANGUAGE[this.globals?.LANG_ID as keyof typeof this.globals.LANGUAGE];  
    }, 1500);
  }

  
  onLoginSubmit() {

    let flg = true;
    const all_error_msg: any = [];
   
    if (this.loginform.email === '') {
      
      all_error_msg.push(this.language.ENTER_YOUR_EMAIL);

      if(flg === true){
          flg = false;
      }
     
    } else if (this.loginform.password === '') {
      

      all_error_msg.push(this.language.ENTER_PASSWORD);

      if(flg === true){
          flg = false;
      }
      
    }
    if(flg == true){
        this.apiservice.getLogin(this.loginform.email, this.loginform.password).subscribe((res: any) => {
          if (res.status) {
            this.comservice.setService('user-details', res.result);
            this.comservice.saveInLocal('user-details', res.result);
            this.comservice.saveInLocal('userId', res.result.id);
            
            this.dataSharingService.isUserLoggedIn.next(true);
            let prevroute: any;
            prevroute = this.comservice.getService('prevroute');
            if (prevroute === 'checkout') {
              this.router.navigate(['/' + prevroute]);
            }else{
              this.router.navigate(['/']);
            }          
          
          } else {
            
            all_error_msg.push(res.message);

            this.onShowAlert('error',all_error_msg); 
          }
        });
      }else{
        this.onShowAlert('error',all_error_msg); 
      }
  }

}
