import { Component, OnInit } from '@angular/core';
import { CommonService } from '../common.service';
import { DataSharingService } from '../data-share.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Globals } from '../globals';
import { ApiService } from '../api.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-recovery',
  templateUrl: './recovery.component.html',
  styleUrls: ['./recovery.component.scss']
})
export class RecoveryComponent implements OnInit {

  public globals: Globals;
  public language: any = null;
  public forgot: any = {pass: '', confirmpass: '' };

  constructor(
    private http: HttpClient,
    private comservice: CommonService,
    private router: Router,
    private dataSharingService: DataSharingService,
    public apiservice: ApiService,
    globals: Globals,
    private route: ActivatedRoute
  ) { 
    this.globals = globals;
    this.language = this.globals.LANGUAGE[this.globals?.LANG_ID as keyof typeof this.globals.LANGUAGE];
  }

 
  onCloseAlert(){
    this.custom_alert_show = false;
    this.custom_alert_type = null;
    this.custom_alert_text = [];
  }

  onShowAlert(type: any,msg: any){
      this.custom_alert_type = type;
      this.custom_alert_text = msg;
      this.custom_alert_show = true;
  }

  custom_alert_header: any = "Recovery Password";
  custom_alert_btnText: any = "Accept"; 
  custom_alert_text: any = []; 
  custom_alert_type: any = "error"; 
  custom_alert_show: any = false; 
  pressCloseOnOutsideClick: any = true;
  alertStyles:any = "";
  headerStyles:any = "";
  textStyles:any = "";
  buttonStyles:any = "";

  ngOnInit(): void {
    setTimeout(() => {
      this.language = this.globals.LANGUAGE[this.globals?.LANG_ID as keyof typeof this.globals.LANGUAGE];  
    }, 1500);
    let id = this.route.snapshot.paramMap.get('id');
    console.log(id);
    this.apiservice.fetchUserByMD5(id).subscribe((res: any) => {
      if (res.status === true) {
        this.forgot.id = res.id;
      } else {

        const all_error_msg: any = [];
        all_error_msg.push("Not valid link");
       
        this.onShowAlert('error',all_error_msg);

        setTimeout(()=>{                           
            this.onCloseAlert(); 
            this.router.navigate(['/']);
         } , 4000);
      }
    });
  }

  updatePass(){
      let flg = true;
      const all_error_msg: any = [];
      if (this.forgot.pass === '') {
        
        all_error_msg.push(this.language.ENTER_NEW_PASSWORD);

        if(flg === true){
            flg = false;
        }
      }
      if (this.forgot.pass.length < 6) {
       
        all_error_msg.push(this.language.ENTER_YOUR_PASSWORD_LENGTH);

        if(flg === true){
            flg = false;
        }
      }
      if (this.forgot.confirmpass === '') {
       
        all_error_msg.push(this.language.ENTER_CONFIRM_PASSWORD);

        if(flg === true){
            flg = false;
        }
      }
      if (this.forgot.confirmpass !== this.forgot.pass) {
        
        all_error_msg.push(this.language.CONFIRM_PASSWORD_NOT_MATCHED_WITH_NEW);

        if(flg === true){
            flg = false;
        }
      }

      if(flg == true){
        this.apiservice.UpdatePass(this.forgot.id, this.forgot.pass).subscribe((res: any) => {
          if (res.status === true) {
            all_error_msg.push(this.language.PASSWORD_SUCCESSFULLY_CHANGED);
            this.onShowAlert("success",all_error_msg); 
            setTimeout(()=>{                           
              this.onCloseAlert(); 
              this.router.navigate(['/login']);
           } , 3000);
  
          }
        });
      }else{
        this.onShowAlert('error',all_error_msg);
      }

  }

}
