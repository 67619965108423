import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  routeUrl = "about";

  testimonialcustomOptions2: OwlOptions = {
    autoplay: false,
    margin: 24,
    loop: true,
    nav: true,
    navText: ['<i class="fa-solid fa-arrow-left"></i>', '<i class="fa-solid fa-arrow-right"></i>'],
    dots: true,
    items: 3,
    responsive: {
        0: {
            items: 1,
            nav: false
        },
        576: {
            items: 1
        },
        768: {
            items: 2
        },
        992: {
            items: 3
        },
        1200: {
            items: 3
        }
    }
   
  }

  constructor() { }

  ngOnInit(): void {
  }

}
