import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { CommonService } from './../common.service';
import { ApiService } from './../api.service';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../globals';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {
  
  gallery_list: any = [];
  gallery_loading: any = 0;

  galleryLoading = true;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private comservice: CommonService,
    private apiservice: ApiService,
    private router: Router,
    private globals: Globals
  ) { }

  ngOnInit(): void {
    this.apiservice.getPhotoByBusiness(this.globals.BUSINESS_ID).subscribe((response: any) => {
        this.gallery_list = response;
        this.gallery_loading = 1;
        this.galleryLoading = false;
    });
  }

}
