import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';

import { CommonService } from './../common.service';
import { ApiService } from './../api.service';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../globals';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {

  public language: any = null;

  public contactDetails: any = {name: '', email: '', phone: '', subject: '', message: ''};

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private comservice: CommonService,
    private apiservice: ApiService,
    private router: Router,
    private globals: Globals,
  ) { 
    this.globals = globals;
    this.language = this.globals.LANGUAGE[this.globals?.LANG_ID as keyof typeof this.globals.LANGUAGE];
  }


  custom_alert_header: any = "Contacts";
  custom_alert_btnText: any = "Accept"; 
  custom_alert_text: any = []; 
  custom_alert_type: any = "error"; 
  custom_alert_show: any = false; 
  pressCloseOnOutsideClick: any = true;
  alertStyles:any = "";
  headerStyles:any = "";
  textStyles:any = "";
  buttonStyles:any = "";

  
onCloseAlert(){
  this.custom_alert_show = false;
  this.custom_alert_type = null;
  this.custom_alert_text = [];

}

onShowAlert(type: any,msg: any){
  this.custom_alert_type = type;
  this.custom_alert_text = msg;
  this.custom_alert_show = true;
}

  ngOnInit(): void {
    setTimeout(() => {
      this.language = this.globals.LANGUAGE[this.globals?.LANG_ID as keyof typeof this.globals.LANGUAGE];  
    }, 1500);

  }

  send_message(){
    let flg = true;
    const all_error_msg: any = [];

    if (this.contactDetails.name == undefined || this.contactDetails.name == '') {
      
      all_error_msg.push("Enter your name");

      if(flg === true){
          flg = false;
      }
    }
    

    if (this.contactDetails.email == '') {
     
       all_error_msg.push(this.language.ENTER_EMAIL_ADDRESS);

        if(flg === true){
            flg = false;
        }
    }else{
      let atpos;
      atpos = this.contactDetails.email.indexOf('@');
      let dotpos;
      dotpos = this.contactDetails.email.lastIndexOf('.');
      if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= this.contactDetails.email.length) {
        
          all_error_msg.push(this.language.ENTER_VALID_EMAIL_ADDRESS);

          if(flg === true){
              flg = false;
          }
      }
    }
    

    if (this.contactDetails.subject == '') {
     
      all_error_msg.push("Enter your message subject");

        if(flg === true){
            flg = false;
        }
    }

    if (this.contactDetails.phone == '') {
     
      all_error_msg.push(this.language.ENTER_YOUR_MOBILE);

        if(flg === true){
            flg = false;
        }
    }

    if (this.contactDetails.message == '') {
     
      all_error_msg.push("Enter your message");

        if(flg === true){
            flg = false;
        }
    }else if(this.contactDetails.message.length < 100){
      all_error_msg.push("Message length minmum 100 characters");

      if(flg === true){
          flg = false;
      }
    }

    if(flg == true){

      this.apiservice.setContactus(this.contactDetails).subscribe((res: any) => {
        if (res.status) {
          
              all_error_msg.push(this.language.THANK_YOU_FOR_CONTACT_US);

              this.onShowAlert('success',all_error_msg);
              setTimeout(()=>{                           
                this.onCloseAlert(); 
        
               } , 4000);
        }
      });

    }else{
      this.onShowAlert('error',all_error_msg);
    }
  }

  
  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

}
