<!--tab2-->
<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 canagepassword">
    <div class="Achd">Canage Password</div>
    
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">  
            <form (ngSubmit)="changePassword()">
                <div class="Acountdet_name"> 
                    <label>Old Password</label>
                    <input type="password" placeholder="******" (keypress)="omit_special_char($event)" [(ngModel)]="userPass.oldpass" class="Acpass" [ngModelOptions]="{standalone:true}">
                </div>    
                <div class="Acountdet_name"> 
                    <label>New Password</label>                                     
                    <input type="password" (keypress)="omit_special_char($event)" placeholder="******" class="Acpass" [(ngModel)]="userPass.newpass" [ngModelOptions]="{standalone:true}">
                </div>

                <div class="Acountdet_name"> 
                    <label>Confirm New Password</label>                                     
                    <input type="password" (keypress)="omit_special_char($event)" placeholder="******" class="Acpass" [(ngModel)]="userPass.confirmpass" [ngModelOptions]="{standalone:true}">
                </div>

                <button type="submit" class="tcBTN"><span>Change Password</span></button>

            </form>

        </div>
    </div>                                    
</div>
<!--tab2 end-->

<app-custom-alert *ngIf="custom_alert_show"
                [header]="custom_alert_header"
                [btnText]="custom_alert_btnText"
                [text]="custom_alert_text"
                [type]="custom_alert_type"
                [show]="custom_alert_show"
                (onClosePressFunction)="onCloseAlert()"
                [pressCloseOnOutsideClick]="pressCloseOnOutsideClick"
                [alertStyles]="alertStyles"
                [headerStyles]="headerStyles"
                [textStyles]="textStyles"
                [buttonStyles]="buttonStyles"
                ></app-custom-alert>
