import { Component, OnInit } from '@angular/core';
import { CommonService } from '../common.service';
import { DataSharingService } from '../data-share.service';
import { Router } from '@angular/router';
import { Globals } from '../globals';
import { ApiService } from '../api.service';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';

import { OrderdetailsComponent } from '../orderdetails/orderdetails.component';

@Component({
  selector: 'app-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.scss']
})
export class OrderHistoryComponent implements OnInit {

  public globals: Globals;
  public language: any = null;
  public userId: any;
  public OrderHistory: any = [];
  isloading: any = 0;


  currentDate:any = new Date();
  

  constructor(
    private http: HttpClient,
    private comservice: CommonService,
    private router: Router,
    private dataSharingService: DataSharingService,
    public apiservice: ApiService,
    globals: Globals,
    private datePipe : DatePipe,
    public dialog: MatDialog,
  ) { 
    this.globals = globals;
    this.language = this.globals.LANGUAGE[this.globals?.LANG_ID as keyof typeof this.globals.LANGUAGE];

  }

  ngOnInit(): void {
    console.log(this.currentDate);
    setTimeout(() => {
      this.language = this.globals.LANGUAGE[this.globals?.LANG_ID as keyof typeof this.globals.LANGUAGE];  
      console.log(this.language);
    }, 2000);
    this.userId = this.comservice.getFromLocal('userId');
    this.apiservice.getOrderByUser((this.globals.APP_TYPE) ? 0 : this.globals.BUSINESS_ID, this.userId).subscribe((response: any) => {
      this.OrderHistory = response;
      this.isloading = 1;
    });
  }

  viewDetails(order: any) {
    const orderdialogRef = this.dialog.open(OrderdetailsComponent, {
      width: '700px',
      data: {
        order: order
      },
      disableClose: true
    });

    orderdialogRef.afterClosed().subscribe(result => {});
  }

}
